import * as React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import './index.scss';

export interface IHomePageLoaderProps {}

export default function HomePageLoader(props: IHomePageLoaderProps) {
  return (
    <div className="w-100">
      <SkeletonTheme color="#e5e5e5;" >
        <div className="w-100 nav">
        
        </div>
        <div className="px-4">
          <Skeleton height={400} />
        </div>

        <div className="mt-2 px-4 ">
          <Skeleton height={54} />
        </div>

        <div className="mt-2 px-4 ">
          <Skeleton width={200} height={20} />
        </div>

        <div className="mt-2 row px-4">
          <div className="col-3">
            <Skeleton height={250} />
          </div>
          <div className="col-3">
            <Skeleton height={250} />
          </div>
          <div className="col-3">
            <Skeleton height={250} />
          </div>
          <div className="col-3">
            <Skeleton height={250} />
          </div>
        </div>

        <div className="mt-2 px-4 ">
          <Skeleton width={200} height={20} />
        </div>
        <div className="mt-2 px-4 ">
          <Skeleton width={350} height={20} />
        </div>

        <div className="mt-2 row px-4">
          <div className="col-3">
            <Skeleton height={250} />
          </div>
          <div className="col-3">
            <Skeleton height={250} />
          </div>
          <div className="col-3">
            <Skeleton height={250} />
          </div>
          <div className="col-3">
            <Skeleton height={250} />
          </div>
        </div>

        <div className="mt-4 px-4">
          <Skeleton height={100} />
        </div>
      </SkeletonTheme>
    </div>
  );
}
