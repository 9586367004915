interface ModuleTypes {
    ADD_MODULE: string;
    EDIT_MODULE: string;
    DELETE_MODULE: string;
    IS_EDITING: string;
    GET_MODULE: string;
    ADD_LESSON: string;
    GET_LESSON: string;
    EDIT_LESSON: string;
    DELETE_LESSON: string;
    IS_EDITING_LESSON: string;
}

const moduleTypes : ModuleTypes = {
    ADD_MODULE: 'ADD_MODULE',
    EDIT_MODULE: 'EDIT_MODULE',
    DELETE_MODULE: 'DELETE_MODULE',
    IS_EDITING: 'IS_EDITING',
    GET_MODULE: 'GET_MODULE',
    GET_LESSON: 'GET_LESSON',
    EDIT_LESSON: 'EDIT_LESSON',
    ADD_LESSON: 'ADD_LESSON',
    DELETE_LESSON: 'DELETE_LESSON',
    IS_EDITING_LESSON: 'IS_EDITING_LESSON',
}

export default moduleTypes;