import React from "react";
import { Route, Redirect } from "react-router-dom";
// import { useSelector } from "react-redux";

// const PrivateRoute = ({ component, ...rest }: any) => {
//   const { isAuthenticated } = useSelector((state: any) => state.auth);

//   const routeComponent = (props: any) =>
//     isAuthenticated ? (
//       React.createElement(component, props)
//     ) : (
//       <Redirect to={{ pathname: "/" }} />
//     );

//   return <Route {...rest} render={routeComponent} />;
// };
const PrivateRoute = ({ component, ...rest }: any) => {
  const token = localStorage.getItem("access");

  const routeComponent = (props: any) =>
    token ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: "/" }} />
    );

  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
