export const ADD_STRUCTURE = 'ADD_STRUCTURE';
export const CREATE_FREE_PLAN = 'CREATE_PLAN';
export const CREATE_ONETIME_PLAN = 'CREATE_ONETIME_PLAN'
export const CREATE_BULK_PLAN = 'CREATE_BULK_PLAN';
export const DELETE_PRICING = 'DELETE_PRICING';
export const GET_PRICINGS = 'GET_PRICINGS';
export const SELECT_PLAN = 'SELECT_PLAN';
export const CLOSE = 'CLOSE';
export const FREE_PLAN = 'FREE';
export const ONETIME_PLAN = 'ONE_TIME';
export const EDIT_PLAN = 'EDIT_PLAN';
export const EDIT_FREEPLAN= 'EDIT_FREEPLAN';
export const BULK = 'BULK';
export const EDIT_ONETIME = 'EDIT_ONETIME';
