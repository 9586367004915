import { IS_UPLOADING, FAILED_UPLOAD, SUCCESS_UPLOAD, RESET_PROGRESS } from 'store/types/progressTypes';

const initialState = {
  percentProgress: 0,
  complete: false,
  progressLoading: false,
  progressError: false,
};

const progressReducer = (state = initialState, {type, payload}:any) => {
  switch (type) {
    case IS_UPLOADING:
      return {
        ...state,
        progressLoading:true,
        percentProgress: payload.percent,
      };
    case FAILED_UPLOAD:
    return {
        ...state,
        progressLoading:false,
        complete:false,
        progressError:true,
    };
    case SUCCESS_UPLOAD:
    return {
        ...state,
        progressLoading:false,
        complete:true,
    };
    case RESET_PROGRESS:
      return initialState;
    default:
      return state;
  }
};

export default progressReducer;