
import {
  // quizPayload,
  ADD_QUIZ,
  // GET_QUIZ,
  GET_QUESTIONS_IN_AN_QUIZ,
  // ADD_QUESTION_TO_QUIZ,
  GET_QUIZ_STATUS,
  QUIZ_LOADING_HANDLER,
} from 'store/types/quizTypes';

const initialState: any = {
  quiz: {},
  questions: [],
  currentQuizStatus: {},
  loading: false,
};

const quizReducer = (state = initialState, { payload, type }: any) => {
  switch (type) {
    case QUIZ_LOADING_HANDLER:
      return {
        ...state,
        loading: !state.loading,
      };
    case ADD_QUIZ:
      return {
        ...state,
        quiz: payload,
      };
    case GET_QUESTIONS_IN_AN_QUIZ:
      return {
        ...state,
        questions: payload,
      };
    case GET_QUIZ_STATUS:
      return {
        ...state,
        currentQuizStatus: payload,
      };
    default:
      return state;
  }
};
export default quizReducer;
