import {
  // SIGNUP,
  SET_CURRENT_USER,
  LOGOUT,
  UPDATE_ACCOUNT_TYPE,
  SET_USER_EMAIL,
  GET_USER_SETTINGS,
} from '../actions/types/authTypes';
import isEmpty from 'helpers/isEmpty';
import jwt_decode from 'jwt-decode';

const token: any = localStorage.getItem('access');
const decodedUser = token ? jwt_decode(token) : null;
// an interface for state initialState would be created Later
const initialState = {
  isAuthenticated: decodedUser ? true : false,
  user: decodedUser || {},
  signup: null,
  modaldetail: null,
  userSettings: {},
};

const authReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: !isEmpty(action.payload),
      };
    case UPDATE_ACCOUNT_TYPE: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        modaldetail: action.payload,
      };
    case GET_USER_SETTINGS:
      return {
        ...state,
        userSettings: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
