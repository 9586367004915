import React, { useState, useEffect } from "react";
import "./AuthProcess.scss";
import SignUp from "./Signup";
import Login from "./Login";

export interface AuthProcessProps {
  authProcess: string;
  display: any;
  closeModal: any;
  banneremail?: any;
  setAuthProcess?: any;
}

const AuthProcess: React.SFC<AuthProcessProps> = ({
  authProcess,
  display,
  closeModal,
  banneremail,
  setAuthProcess,
}) => {
  const [authProcessState, setAuthProcessState] = useState(authProcess);

  useEffect(() => {
    authProcess === "SIGNIN" && setAuthProcessState("SIGNIN");
    authProcess === "LOGIN" && setAuthProcessState("LOGIN");
  }, [authProcess]);

  return (
    <div className="auth-process">
      {authProcessState === "SIGNIN" && (
        <SignUp
          switchAuthProcessState={() => {
            setAuthProcessState("LOGIN");
          }}
          banneremail={banneremail}
          display={display}
          closeModal={closeModal}
          setAuthProcess={setAuthProcess}
        />
      )}

      {authProcessState === "LOGIN" && (
        <Login
          banneremail={banneremail}
          switchAuthProcessState={() => setAuthProcessState("SIGNIN")}
          display={display}
          closeModal={closeModal}
          setAuthProcess={setAuthProcess}
        />
      )}
    </div>
  );
};

export default AuthProcess;
