import React, { useState } from 'react';
import onClickOutside from 'react-onclickoutside';
import Avatar from 'components/common/Avatar';
import ProfileDropdown from 'components/common/NavigationBar/ProfileDropdown';
import { useSelector } from 'react-redux';

function Menu() {
  const { user } = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  Menu.handleClickOutside = () => setIsOpen(false);
  return (
    <div>
      <Avatar
        firstName={user?.firstname}
        lastName={user?.lastname}
        image={user?.image}
        onClick={toggle}
      />

      {isOpen && <ProfileDropdown user={user} />}
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Menu.handleClickOutside,
};

export default onClickOutside(Menu, clickOutsideConfig);
