import * as React from "react";
import Close from "../../../assets/images/svg/modal-close.svg";
import "./Modal.scss";

export interface ModalProps {
  children?: React.ReactNode;
  display?: boolean;
  closeModal?: any;
  canClose?: boolean;
}

const Modal: React.SFC<ModalProps> = ({
  children,
  display,
  closeModal,
  canClose = true,
}) => {
  const showModal: string = display ? "show" : "hide";
  return (
    <div className={`appmodal appmodal--${showModal}`}>
      <div className={`appmodal__container`}>
        <div className='appmodal__content'>
          {canClose && (
            <span
              role='button'
              className='appmodal__close'
              onClick={closeModal}
            >
              <img src={Close} alt='close icon' width='32px' />
            </span>
          )}
          {children}
        </div>
      </div>
      <div className={`appmodal__background appmodal--${showModal}`}></div>
    </div>
  );
};

export default Modal;
