import { lazy } from 'react';
const CartPage = lazy(() => import('pages/CartPage'));
const MyCourses = lazy(() => import('pages/MyCourses'));
const Courses = lazy(() => import('pages/Courses'));
const Support = lazy(() => import('pages/Support'));
const Privacy = lazy(() => import('pages/PrivacyAndTerms'));
const AboutUs = lazy(() => import('pages/AboutUs'));
const Home = lazy(() => import('pages/HomePage'));
const StudentHomePage = lazy(() => import('pages/StudentHomePage'));
const ViewCourse = lazy(() => import('pages/ViewCourse'));
const StartCourse = lazy(() => import('pages/StartCourse'));
const ConfirmRole = lazy(() => import('pages/Auth/ConfirmRole'));
const ConfirmSocial = lazy(() => import('pages/ConfirmSocial'));
const VendorHome = lazy(() => import('pages/VendorHome'));
const VendorCourses = lazy(() => import('pages/VendorCourses'));
const MyModules = lazy(() => import('pages/MyCourseContent'));
const VendorSetting = lazy(() => import('pages/VendorSettings'));
const SalesReport = lazy(() => import('pages/SalesReport'));

interface routeObject {
  url: string;
  // component: ({}: any) => JSX.Element;
  component: any;
  exact?: boolean;
}

export const privateRoutes: routeObject[] = [
  {
    url: '/my-courses',
    component: MyCourses,
  },

  {
    url: '/view-course/:courseId/',
    component: ViewCourse,
  },
  {
    url: '/course/:courseId',
    component: StartCourse,
  },
  {
    url: '/my-courses',
    component: MyCourses,
  },
  {
    url: '/confirm-role',
    component: ConfirmRole,
  },

  {
    url: '/home',
    component: StudentHomePage,
  },
  {
    url: '/instructor/courses/course/:courseId',
    component: MyModules,
  },
  {
    url: '/instructor/courses',
    component: VendorCourses,
  },
  {
    url: '/instructor/settings',
    component: VendorSetting,
  },
  {
    url: '/instructor',
    component: VendorHome,
    exact:true
  },
  {
    url: '/instructor-reports/',
    component: SalesReport,
  },
];

export const publicRoutes: routeObject[] = [
  {
    url: '/',
    component: Home,
  },
  {
    url: '/confirm/role',
    component: ConfirmSocial,
  },
  {
    url: '/cart',
    component: CartPage,
  },
  {
    url: '/courses/:categoryId',
    component: Courses,
  },
  {
    url: '/courses',
    component: Courses,
  },
  {
    url: `/privacy${'&'}terms`,
    component: Privacy,
  },
  {
    url: '/about-us',
    component: AboutUs,
  },

  {
    url: '/support',
    component: Support,
  },
  {
    url: '/support/:title',
    component: Support,
  },
];
