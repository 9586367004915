import courseModuleTypes from 'store/types/courseModuleTypes';
import { sendRequest } from 'config/api';
import { handleLoading } from 'store/actions/loadingActions';


const {
  // ADD_MODULE,
  // IS_EDITING,
  EDIT_MODULE,
  DELETE_MODULE,
  GET_MODULE,
  // ADD_LESSON,
  // DELETE_LESSON,
  // IS_EDITING_LESSON,
} = courseModuleTypes;

export const getCourse = (courseId: any, toast: any) => async (
  dispatch: any
) => {
  try {
    dispatch(handleLoading());
    const { data }: any = await sendRequest(`/lms/course/${courseId}`);
    dispatch({
      type: GET_MODULE,
      payload: { course: data.course? data.course: data, courseId },
    });
    dispatch(handleLoading());
  } catch ({ response, message }) {
    dispatch(handleLoading());
    toast.error(response?.data?.detail || message);
  }
};

export const addModule = (moduleData: any, toast: any, history:any) => async (
  dispatch: any
) => {
  try {
    dispatch(handleLoading());
    const{data}:any=await sendRequest('/lms/module/', moduleData, 'POST');
    await dispatch(getCourse(moduleData.course, toast));
    dispatch(handleLoading());
    toast.success('Added module successfully');
    history.push(`/instructor/courses/course/${moduleData.course}/course-content/${data.id}#Lessons`)
  } catch ({ response, message }) {
    dispatch(handleLoading());
    toast.error(response?.data || message);
    return response?.data || message;
  }
};

export const editModule = (
  courseId: any,
  moduleId: any,
  moduleData: any,
  toast: any,
  history:any,
) => async (dispatch: any) => {
  try {
    dispatch(handleLoading());
    const { data }: any = await sendRequest(
      `/lms/module/${moduleId}/`,
      moduleData,
      'PATCH'
    );
    
    dispatch({ type: EDIT_MODULE, payload: { moduleId, moduleData: data } });
    history.push(`/instructor/courses/course/${courseId}/course-content/${moduleId}`)
    dispatch(getCourse(courseId, toast))
    dispatch(handleLoading());
  } catch ({ response, message }) {
    toast.error(response?.data || message);
  }
};

export const deleteModule = (moduleId: any,courseId:any, toast: any) => async (
  dispatch: any
) => {
  try {
    
    await sendRequest(`/lms/module/${moduleId}`, {}, 'DELETE');
    dispatch({
      type: DELETE_MODULE,
      payload: { moduleId },
    });
    // dispatch(handleLoading());
    await dispatch(getCourse(courseId, toast))
    toast.success('successfully deleted module');
  } catch ({ response, message }) {
    toast.error(response?.data || message);
    return response || message;
  }
};

export const addLesson = (lessonData:any, courseId:any,moduleId:any, toast:any) => async (dispatch: any) => {
  try {
    // dispatch(handleLoading());
    await sendRequest(`/lms/lesson/`, lessonData, 'POST',undefined, dispatch);
    dispatch({type:'SUCCESS_UPLOAD'});
    await dispatch(getCourse(courseId, toast))
    // dispatch(handleLoading());
    toast.success('successfully added lesson')
  } catch ({ response, message }) {
    // dispatch(handleLoading());
    dispatch({type:'FAILED_UPLOAD'});
    toast.error(response?.data?.non_field_errors[0]||'An error occured' || message);
    return response || message;
  }
}

export const deleteLesson = (courseId:any, lessonId:any, toast:any) => async(dispatch:any) => {
  try {
    // dispatch(handleLoading())
    await sendRequest(`/lms/lesson/${lessonId}/`,{}, 'DELETE');
    await dispatch(getCourse(courseId, toast))
    // dispatch(handleLoading)
    toast.success('successfuly deleted lesson')
  } catch ({response, message}) {
    toast.error(response?.data || message);
    return response || message;
  }
}

export const editLesson = (courseId:any, lessonId:any, lessonData:any, toast:any) => async(dispatch:any) => {
  try {
    // dispatch(handleLoading())
    await sendRequest(`/lms/lesson/${lessonId}/`,lessonData, 'PATCH');
    // dispatch(handleLoading());
    await dispatch(getCourse(courseId, toast))
    toast.success('successfuly edited lesson')
  } catch ({response, message}) {
    toast.error(response?.data || message);
    return response || message;
  }
}

export const deleteLessonMaterial = (materialId:any) => async(dispatch:any) => {
  try{
    await sendRequest(`/lms/lesson_material/${materialId}/`,{},'DELETE')
  }  catch ({response, message}) {
    // toast.error(response?.data || message);
    return response || message;
  }
}