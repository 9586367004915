import { ADD_NOTES } from 'store/types/noteTypes';
import { sendRequest } from 'config/api';


interface INoteData {}
export const addNote = (noteData: INoteData) => async (dispatch: any) => {
  try {
    const { data }: any = await sendRequest(
      '/lms/lesson-notes/',
      noteData,
      'POST'
    );
    dispatch({
      type: ADD_NOTES,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};
