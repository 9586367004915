import React, { useState } from 'react';
import './Avatar.scss';
import { whiteTrash } from 'assets/images/svg';
export interface AvatarProps {
  image?: string;
  firstName?: string;
  lastName?: string;
  onClick?: any;
  showTrashOnHover?: boolean;
}
const Avatar: React.SFC<AvatarProps> = ({
  image,
  firstName,
  lastName,
  onClick,
  showTrashOnHover = false,
}) => {
  const AvatarStyle = {
    backgroundSize: 'cover',
    backgroundImage: `url(${image})`,
  };
  const [showTrash, setShowTrash] = useState(false);

  return (
    <div
      className={`avatar`}
      style={image ? AvatarStyle : undefined}
      onClick={onClick}
      onMouseEnter={() => setShowTrash(true)}
      onMouseLeave={() => setShowTrash(false)}
    >
      {image && showTrashOnHover && showTrash && (
        <div className={`${showTrash ? 'avatar--background' : ''}`}></div>
      )}
      {image && showTrashOnHover && showTrash && (
        <div className='avatar__trash'>
          <img src={whiteTrash} alt='trash' />
        </div>
      )}
      {image === null || image === '' ? (
        <span className='avatar__text'>{firstName?.charAt(0)}</span>
      ) : null}
    </div>
  );
};

export default Avatar;
