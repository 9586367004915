export const SIGNUP = 'SIGNUP';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_ERROR = 'SET_CURRENT_USER_ERROR';
export const LOGOUT = 'LOGOUT';
export const SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE';
export const UPDATE_ACCOUNT_TYPE = 'UPDATE_ACCOUNT_TYPE';
export const LOGIN = 'LOGIN';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
