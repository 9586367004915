import { GET_WISHLIST, WishlistStateI } from "store/types/wishlistTypes";

const initialState: WishlistStateI = {
  course: {},
};

const wishlistReducter = (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_WISHLIST:
      return {
        ...state,
        course: payload,
      };
    default:
      return state;
  }
};

export default wishlistReducter;
