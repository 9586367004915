// import { get } from "http"
// import { type } from "os"

export const GET_QUESTIONS = 'GET_QUESTION'
export const CREATE_QUESTION = 'CREATE_QUESTION'

//typings for payload
export type createQuestionPayload = {
  question: string,
  lesson: string,
}


//actions
export type GetQuestionsAction = {
  type: typeof GET_QUESTIONS
  payload: createQuestionPayload
}

export type CreateQuestionAction = {
  type: typeof CREATE_QUESTION
  payload: createQuestionPayload
}
//reducer 
export interface IQuestionAndAnswerState {
  questionsAndAnswers: any,
  [key: string]: any
}

export type QuestionAndAnswerTypes = GetQuestionsAction | CreateQuestionAction 