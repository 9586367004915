import cartTypes from "../types/cartTypes";

const {
  ADD_ITEM,
  REMOVE_ITEM,
  VERIFY_COUPON_CODE,
  GET_CART_SUCCESS,
  ADD_WISHLIST,
  CHECK_OUT,
  DELETE_COUPON,
  LOADING,
  CLEAR,
  ORDER_FAILURE,
  ORDER_SUCCESS,
} = cartTypes;

const initialState = {
  loading: false,
  cartItems: [],
  wishList: [],
  coupon: "",
  error: "",
  success: false,
  failure: false,
};

const cartReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    case ORDER_FAILURE:
      return {
        ...state,
        failure: payload.failure,
      }
    case ORDER_SUCCESS:
      return {
        ...state,
        success: payload.success,
        loading: payload.loading,
      }
    case ADD_ITEM:
      return {
        ...state,
        cartItems: payload.cartItems,
      };
    case REMOVE_ITEM:
      return {
        ...state,
        cartItems: payload.cartItems.filter(
          (each) => each.id !== payload.courseId
        ),
      };
    case VERIFY_COUPON_CODE:
      return {
        ...state,
        coupon: payload.coupon,
      };
    case DELETE_COUPON:
      return {
        ...state,
        coupon: payload.coupon,
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        cartItems: payload.cartItems,
        cartTotal: payload.cartTotal,
        loading: payload.loading,
      };
    case ADD_WISHLIST:
      return {
        ...state,
        wishList: payload.wishList,
      };
    case CHECK_OUT:
      return {
        ...state,
        loading: "false",
        wishList: [],
        coupon: "",
        error: "",
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default cartReducer;
