import * as React from "react";
import "./SignUp.scss";
import { useDispatch } from "react-redux";
import { signup } from "store/actions/authActions";
import { useHistory } from "react-router-dom";
import Button from "../../../common/Button";
import Input from "../../../common/Input";
import Radio from "components/common/Radio";
import ErrorMessage from "components/common/ErrorMessage";
import Logo from "../../../../assets/images/png/didemy_04.png";
import Google from "../../../../assets/images/svg/google-icon.svg";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { signUpValidationSchema } from "helpers/validations";
import { useToast } from "context/toastContext";
import Modal from "components/common/Modal";
import { setModalData } from "store/actions";

export interface SignUpProps {
  switchAuthProcessState: any;
  closeModal: any;
  display: any;
  banneremail?: any;
  setAuthProcess?: any;
}

interface SignUpValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  accountType: string;
}

const SignUp: React.SFC<SignUpProps> = ({
  switchAuthProcessState,
  closeModal,
  display,
  banneremail,
  setAuthProcess,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit: any = async (
    data: any,
    { setSubmitting, resetForm }: any
  ) => {
    const signupData = {
      firstname: data.firstName,
      lastname: data.lastName,
      email: data.email,
      password: data.password,
      account_type: data.accountType,
    };

    const error = await dispatch(signup(signupData, toast));
    if (!error) {
      resetForm();
      setSubmitting(false);
      closeModal();
      history.push("/");
      return;
    }

    setSubmitting(false);
  };

  const initialValues: SignUpValues = {
    firstName: "",
    lastName: "",
    email: banneremail ? banneremail : "",
    password: "",
    accountType: "",
  };

  const handleClosemodal = async () => {
    const data = { email: "", status: false };
    setAuthProcess("");
    await dispatch(setModalData(data));
  };

  return (
    <Modal
      display={display}
      closeModal={() => {
        closeModal();
        handleClosemodal();
      }}
    >
      <div className="signup">
        <div className="signup__intro d-flex flex-column align-items-center">
          <img src={Logo} alt="logo" width="50px" />
          <h1 className="signup__title">Create an account</h1>
          <p className="signup__subtitle">Gain valuable knowledge today.</p>
        </div>
        <div className="d-flex flex-column  signup__social">
          <a href="https://dev.api.peerlesslms.com/api/v1/accounts/google/login">
            <Button
              textContent="Sign up with Google"
              iconLeft={Google}
              buttonStyle="btn--secondary mb-2"
            />
          </a>
        </div>

        <div className="d-flex justify-content-between align-items-center my-4">
          <hr className="signup__line" />
          <span className="px-2 signup__divider">or</span>
          <hr className="signup__line" />
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={yup.object({
            ...signUpValidationSchema,
          })}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            setFieldValue,
            handleBlur,
            errors,
            touched,
          }) => (
            <Form>
              <div className="d-flex mb-3 flex-col">
                <Input
                  type="email"
                  required
                  name="email"
                  placeholder="Email"
                  value={values.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
              {errors.email && touched.email && (
                <ErrorMessage errorMessage={errors.email} />
              )}
              <div className="d-flex mb-3">
                <Input
                  type="password"
                  required
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
              {errors.password && touched.password && (
                <ErrorMessage errorMessage={errors.password} />
              )}
              <div className="d-flex justify-content-between mb-3">
                <div className="mr-3">
                  <Input
                    type="text"
                    required
                    name="firstName"
                    placeholder="First Name"
                    value={values.firstName}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  {errors.firstName && touched.firstName && (
                    <ErrorMessage errorMessage={errors.firstName} />
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    required
                    name="lastName"
                    placeholder="Last Name"
                    value={values.lastName}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  {errors.lastName && touched.lastName && (
                    <ErrorMessage errorMessage={errors.lastName} />
                  )}
                </div>
              </div>
              <Radio
                htmlForId="student"
                name="account_type"
                labelText="Student"
                value={values.accountType}
                handleChange={() => setFieldValue("accountType", "LEARNER")}
              />
              <Radio
                htmlForId="instructor"
                name="account_type"
                labelText="Instructor"
                value={values.accountType}
                handleChange={() => setFieldValue("accountType", "VENDOR")}
              />
              {errors.accountType && touched.accountType && (
                <ErrorMessage errorMessage={errors.accountType} />
              )}

              <div className="d-flex flex-row my-4">
                <Button
                  textContent="Sign Up"
                  buttonStyle="btn--default"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>

        <p className="signup__policy-text ">
          By creating an account you agree to our Terms & Privacy Policy.
        </p>
      </div>
      <div className="signup__footer">
        <p className="signup__login-option">
          Already signed up?{" "}
          <span
            className="ml-1"
            role="button"
            tabIndex={0}
            onClick={() => switchAuthProcessState()}
          >
            Log in
          </span>
        </p>
      </div>
    </Modal>
  );
};

export default SignUp;
