//  root reducer
import { combineReducers } from "redux";
import auth from "./authReducer";
import course from "./courseReducer";
import cart from "./cartReducer";
import wishlist from "./wishlistReducer";
import lesson from './lessonReducer'
import loading from './loadingReducer'
import questionAndAnswer from './questionReducer'
import quiz from './quizReducer'
import vendorCourse from './courseModulesReducer';
import uploadProgress from './progressReducer';
import pricingReducer from './pricingReducer';

export const rootReducer = combineReducers({
  pricingReducer,
  auth,
  vendorCourse,
  course,
  cart,
  wishlist,
  lesson,
  loading,
  questionAndAnswer,
  quiz,
  uploadProgress,
});

// export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
