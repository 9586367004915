import {GET_QUESTIONS, IQuestionAndAnswerState} from 'store/types/questionTypes'

const initialState: IQuestionAndAnswerState = {
 questionsAndAnswers : {}
}

const questionReducer = (state = initialState, {payload,type}:any)=> {
  switch (type) {
    case GET_QUESTIONS:
      return {
        ...state,
        questionsAndAnswers: payload
      }
    default:
      return state;
  }
}
export default questionReducer