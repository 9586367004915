import * as React from "react";
import "./ErrorMessage.scss";

export interface ErrorMessageProps {
  errorMessage?: any;
  className?: string
}

const ErrorMessage: React.SFC<ErrorMessageProps> = ({ errorMessage, className="" }) => {
  return (
    <div className={`errorMessage ${className}`}>
      <div className='errorMessage__triangle'></div>
      {errorMessage}
    </div>
  );
};

export default ErrorMessage;
