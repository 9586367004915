import {
  ADD_ASSIGNMENT,
  IAssignmentPayload,
} from 'store/types/assignmentTypes';
import { sendRequest } from 'config/api';
import { getCourse } from './courseModuleActions';
import { toast } from 'react-toastify';

export const addAssignment = (assignmentdata: IAssignmentPayload) => async (
  dispatch: any
) => {
  try {
    const { data } = await sendRequest(
      `/task/assignment/`,
      assignmentdata,
      'POST'
    );
    dispatch({
      type: ADD_ASSIGNMENT,
      payload: data,
    });
    // dispatch(handleLoading());
    const courseId = assignmentdata.course;
    await dispatch(getCourse(courseId, toast));
    // toast.success('Assignment Submitted Successfully');
    return;
  } catch ({ response, message }) {
    return response?.data || message;
    // toast.error('Assignment submitted already!');
  }
};

export const updateAssignment = (
  assignmentdata: IAssignmentPayload,
  assignmentId: string,
  toast: any
) => async (dispatch: any) => {
  try {
    const { data } = await sendRequest(
      `/task/assignment/${assignmentId}/`,
      assignmentdata,
      'PATCH'
    );

    const courseId = data.course;

    //this is reloading the entire component
    dispatch(getCourse(courseId, toast));

    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const deleteAssignment = (
  assignmentId: string,
  courseId: string
) => async (dispatch: any) => {
  try {
    await sendRequest(`/task/assignment/${assignmentId}/`, undefined, 'DELETE');

    dispatch(getCourse(courseId, toast));
    toast.success('Deleted Sucessfull');
    return;
  } catch ({ response, message }) {
    toast.error(JSON.stringify(response.data || message));
    return response.data || message;
  }
};

export const submitAssignment = (
  assignmentData: any,
  courseId: string
) => async (dispatch: any) => {
  try {
    await sendRequest(`/task/assignment_result/`, assignmentData, 'POST', undefined, dispatch);
    toast.success('Assignment submited sucessfully');
    return;
  } catch ({ response, message }) {
    toast.error(JSON.stringify(response.data || message));
    return response.data || message;
  }
};
