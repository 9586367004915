import { sendRequest } from 'config/api';
import setAuthToken from 'helpers/setAuthToken';
import jwt_decode from 'jwt-decode';
import { handleLoading } from 'store/actions/loadingActions';
import {
  SIGNUP,
  // UPDATE_PROFILE,
  SET_CURRENT_USER,
  // SET_CURRENT_USER_ERROR,
  // LOGOUT,
  SET_ACTIVE_ROLE,
  // UPDATE_ACCOUNT_TYPE,
  SET_USER_EMAIL,
  GET_USER_SETTINGS,
} from 'store/actions/types/authTypes';

export const setCurrentUser = (decoded) => async (dispatch) => {
  try {
    const { user_id } = decoded;
    const { data } = await sendRequest(`/auth/users/${user_id}`);
    dispatch({
      type: SET_CURRENT_USER,
      payload: data,
    });
    return;
  } catch (error) {
    return error?.response?.data || error?.message;
  }
};

export const signup = (signupData, toast) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      '/auth/users/register/',
      signupData,
      'POST'
    );
    dispatch({
      type: SIGNUP,
      payload: data,
    });
    toast.success('Signup Successful. Please log in with credentials');
    return;
  } catch (error) {
    console.log(error?.response?.data?.message);
    toast.success(error?.response?.data?.message[0] || 'An error occured');
    return error?.response?.data?.message[0] || error?.message[0];
  }
};

export const login = (loginData, toast) => async (dispatch) => {
  try {
    const { data } = await sendRequest('/auth/login/', loginData, 'POST');
    const { access, refresh } = data;
    localStorage.setItem('refresh', refresh);
    localStorage.setItem('access', access);
    const decoded_user = await jwt_decode(access);
    dispatch(setCurrentUser(decoded_user));
    toast.success('Login Successful');
    return;
  } catch (error) {
    toast.error(error?.response?.data?.detail || error?.message);
    return error?.response?.data || error?.message;
  }
};

export const loginSocial = (access, refresh) => async (dispatch) => {
  try {
    localStorage.setItem('refresh', refresh);
    localStorage.setItem('access', access);
    const decoded_user = await jwt_decode(access);
    dispatch(setCurrentUser(decoded_user));
  } catch (error) {
    return error?.response?.data || error?.message;
  }
};

export const updateAccountType = (userId, accountType) => async (dispatch) => {
  try {
    await sendRequest(
      `/auth/users/${userId}/change-role/`,
      accountType,
      'POST'
    );
    const access = localStorage.getItem('access');
    const decoded_user = await jwt_decode(access);
    await dispatch(setCurrentUser(decoded_user));
  } catch (error) {
    return error?.response?.data || error?.message;
  }
};

export const updateUserSettings = (updateData, toast) => async (dispatch) => {
  try {
    console.log(updateData, '$$$');
    await sendRequest(`/auth/users/settings/update/`, updateData, 'PATCH');
    // const access = localStorage.getItem('access');
    // const decoded_user = await jwt_decode(access);
    // await dispatch(setCurrentUser(decoded_user));
    toast.success('Profile Updated Successfully');
    return;
  } catch (error) {
    toast.error('Profile could not be updated');
    return error?.response?.data || error?.message;
  }
};

export const getUserSettings = () => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/auth/users/settings/`);
    console.log('New data', data);
    dispatch(handleLoading());
    dispatch({
      type: GET_USER_SETTINGS,
      payload: data,
    });
    dispatch(handleLoading());
    return;
  } catch (error) {
    return error?.response?.data || error?.message;
  }
};

export const setActiveRole = (userRole) => (dispatch) => {
  localStorage.setItem('activeRole', userRole);
  return dispatch({
    type: SET_ACTIVE_ROLE,
    payload: userRole,
  });
};

// Log user out
export const logout = () => (dispatch) => {
  // Remove the token from localStorage
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  localStorage.removeItem('activeRole');
  // Remove the auth header from future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const setModalData = (modaldata) => (dispatch) => {
  return dispatch({
    type: SET_USER_EMAIL,
    payload: modaldata,
  });
};

export const sendUserCategory = (userid, categorydata, toast) => async (
  dispatch
) => {
  try {
    const { data } = await sendRequest(
      `/auth/users/${userid}/`,
      categorydata,
      'PATCH'
    );
    dispatch({
      type: SET_CURRENT_USER,
      payload: data,
    });
    toast.success('Updated Categories Successfully');
    return;
  } catch (error) {
    toast.success(error?.reponse?.data?.detail || error?.message);
    return error?.response?.data || error?.message;
  }
};
