import * as Yup from 'yup';
export const signUpValidationSchema = {
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .max(50, 'Must be 50 characters or less')
    .min(6, 'Must be above 6 characters'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  accountType: Yup.string().required('Select an account type'),
};
export const loginValidationSchema = {
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
};

export const selectCategory = {
  category: Yup.array().required('Please check at least one user'),
};

export const createQuizValidationSchema = Yup.object({
  title: Yup.string()
    .required('This field is required')
    .max(500, 'Should be 500 characters or less'),
  // description: Yup.string()
});

export const createCourseValidationSchema = {
  title: Yup.string()
    .max(60, 'Title is too long')
    .required('Title is required'),
  summary: Yup.string()
    .required('Summary is required')
    .max(90, 'Summary is too long'),
  category: Yup.string().required('Category is required'),
};

export const addModuleValidationSchema = {
  name: Yup.string().required('Required'),
};

export const updateCourseInformationValidationSchema = {
  title: Yup.string()
    .max(60, 'Title is too long')
    .required('Title is required'),
  summary: Yup.string()
    .required('Summary is required')
    .max(90, 'Summary is too long'),
  category: Yup.string().required('Category is required'),
  course_level: Yup.string().required('Level is required'),
};

export const lessonValidationSchema = {
  title: Yup.string()
    .max(60, 'Title is too long')
    .required('Title is required'),
  type: Yup.string().required('please select appropriate course type'),
  upload: Yup.string().required(
    'course link or link to course resource is required',
  ),
  lessonMaterials: Yup.array().of(
    Yup.object().shape({
      material_type: Yup.string().required('select a file or link type'),
      material_file: Yup.string().when('material_type', {
        is: '',
        then: Yup.string().required('Select a file type or enter a file link'),
        otherwise: Yup.string().nullable(),
      }),
      material_link: Yup.string().when('material_file', {
        is: '',
        then: Yup.string().required('link is required'),
        otherwise: Yup.string().url('link must be a valid url').nullable(),
      }),
    }),
  ),
};

export const singleOptionSchema = Yup.object({
  options: Yup.array().of(
    Yup.object().shape({
      value: Yup.string()
        .max(200, "Option shouldn't be more than 200")
        .required('This field is required'),
    }),
  ),
});

export const addAssignmentSchema = Yup.object({
  instruction: Yup.string()
    .required('This field is required')
    .max(500, 'Should be 500 characters or less'),
  details: Yup.string().max(500, 'Should be 500 characters or less'),
  // description: Yup.string()
});

function formatDate(date) {
  return new Date(date).toLocaleDateString();
}

export const oneTimePurchaseFormSchema = Yup.object().shape({
  currency: Yup.string().required(),
  amount: Yup.number().required().min(0),
  discount: Yup.number().min(1).max(100).nullable(),
  discount_start: Yup.date().when('discount', {
    is: undefined,
    then: Yup.date().nullable(),
    otherwise: Yup.date().min(new Date(), 'Date must be later than today.').required('Date is required'),
  }),
  discount_end: Yup.date().when('discount', {
    is: undefined,
    then: Yup.date().nullable(),
    otherwise: Yup.date()
      .required()
      .min(
        Yup.ref('discount_start'),
        ({ min }) => `Date needs to be after ${formatDate(min)}`
      ),
  }),
  name: Yup.string().required(),
});

export const freePlanSchema = Yup.object({
  name: Yup.string().required(),
});


export const accountSchema = Yup.object({
  bank: Yup.string().required('select a your bank'),
  account_number: Yup.number().required('Enter your account number').min(10),
})

export const verificationCodeSchema = Yup.object({
  first: Yup.string()
  .required()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(1, 'Only 1 digit per field')
  .max(1, 'Only 1 digit per field'),
  second: Yup.string()
  .required()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(1, 'Only 1 digit per field')
  .max(1, 'Only 1 digit per field'),
  third: Yup.string()
  .required()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(1, 'Only 1 digit per field')
  .max(1, 'Only 1 digit per field'),
  fourth: Yup.string()
  .required()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(1, 'Only 1 digit per field')
  .max(1, 'Only 1 digit per field'),
  fifth: Yup.string()
  .required()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(1, 'Only 1 digit per field')
  .max(1, 'Only 1 digit per field'),
})