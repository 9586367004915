import { GET_LESSON } from 'store/types/lessonTypes';
import { ADD_NOTES } from 'store/types/noteTypes';

// interface Ilesson{
//   personal_note
// }
const initialState = {
  lesson: {},
};

const lessonReducer = (state: any = initialState, { type, payload }: any) => {
  switch (type) {
    case GET_LESSON:
      return {
        ...state,
        lesson: payload,
      };
    case ADD_NOTES:
      return {
        ...state,
        lesson: {
          ...state.lesson,
          personal_notes: [payload, ...state.lesson.personal_notes],
        },
      };
    default:
      return state;
  }
};

export default lessonReducer;
