import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import { useWindowSize } from 'helpers/customHooks';
import { ReactComponent as BookIcon } from 'assets/images/svg/courses.svg';
import { ReactComponent as CategoryIcon } from 'assets/images/svg/category.svg';
import { ReactComponent as CartIcon } from 'assets/images/svg/cart.svg';
import { ReactComponent as BellIcon } from 'assets/images/svg/bell.svg';
import LogoIcon from 'assets/images/png/didemy06.png';
import Avatar from 'components/common/Avatar';
import ResponsiveNavigationBar from 'components/common/NavigationBar/ResponsiveNavigationBar';
import SearchBar from 'components/common/SearchButton';
import { getCategories, getVendorCourses } from 'store/actions';
import './index.scss';
import AuthProcess from 'components/layout/AuthProcess';
import NavMenu from 'components/common/NavigationBar/DropdownMeun';
import CartDropdown from 'components/common/NavigationBar/CartDropdown';
import NotificationMenu from 'components/common/NavigationBar/Notification';
import Modal from 'components/common/Modal';
import { home, reports } from 'assets/images/svg';
import { getAll } from 'store/actions/cartActions';
import { useToast } from 'context/toastContext';
import AvatarProfileDropdown from './DropdownProfileAvatar';

interface Props {
  width?: number;
  banneremail?: string;
}

const NavigationBarComponent: React.FC<Props> = () => {
  const { modaldetail } = useSelector((state: any) => state.auth);
  const [navigationModal, setNavigationModal] = useState(false);
  const [toggleNavmenu, settoggleNavmenu] = useState(false);
  const [toggleProfilemenu, settoggleProfilemenu] = useState(false);
  const [toggleNotificationemenu, settoggleNotificationmenu] = useState(false);
  const [toggleCartmenu, settoggleCartmenu] = useState(false);
  let [courseSearchValue, setcourseSearchValue] = useState('');
  const toast = useToast();

  const { isAuthenticated, user } = useSelector((state: any) => state.auth);
  const { image, firstname, lastname, account_type } = useSelector(
    (state: any) => state.auth.user
  );
  const { cartItems, cartTotal } = useSelector((state: any) => state.cart);

  const accountType: any = user?.account_type;
  const dispatch = useDispatch();
  // Auth Process can either be Login or SignUp
  const [authProcess, setAuthProcess] = useState('');
  const width = useWindowSize();
  let SearchIcon = <i className='fa fa-search'></i>;
  let url: any;
  let StudentwithNoCategory: boolean = false;

  const {
    user: { roles },
  } = useSelector((state: any) => state.auth);

  useEffect(() => {
    fetchCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCart = () => {
    dispatch(getAll(toast));
  };

  useEffect(() => {
    setAuthProcess('');
    if (modaldetail?.status) {
      setNavigationModal(true);
      setAuthProcess('SIGNIN');
    }
  }, [modaldetail]);

  useEffect(() => {
    fetchCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const fetchCategories = async () => {
    await dispatch(getCategories());
    await dispatch(getVendorCourses(url));
  };

  const handleKeyPress = (event: any) => {
    let searchvalue = event.target.value;

    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.target.value !== '') {
        dispatch(
          getVendorCourses(`/lms/vendor-courses/?search=${searchvalue}`)
        );
      } else {
        setcourseSearchValue('');
        dispatch(getVendorCourses(url));
      }
    }
  };

  const handleSearch = () => {
    if (courseSearchValue !== '') {
      dispatch(
        getVendorCourses(`/lms/vendor-courses/?search=${courseSearchValue}`)
      );
    }
  };

  const handleBlur = (value: string) => {
    if (value !== '') {
      setcourseSearchValue(value);
    } else {
      setcourseSearchValue('');
      dispatch(getVendorCourses(url));
    }
  };

  StudentwithNoCategory =
    accountType === 'LEARNER' &&
    isAuthenticated &&
    user?.preferred_category?.length === 0;

  return (
    <section className='navbar_section'>
      <div className='container wider_container'>
        {width > 998 ? (
          <Navbar expand='lg' bg='dark' variant='dark' className='bg_nav'>
            <Navbar.Brand href='/' className='mx-auto'>
              <img src={LogoIcon} alt='Logo' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse
              id={StudentwithNoCategory ? 'opacity' : 'basic-navbar-nav'}
            >
              <Nav className='mr-auto mx-auto '>
                {account_type === 'VENDOR' ? (
                  <Link to='/' className='nav-link d-flex align-items-center'>
                    <img src={home} alt='courses' />
                    <span className='nav_title ml-1'>Home</span>
                  </Link>
                ) : (
                  <Link to='/courses' className='nav-link'>
                    <BookIcon className='navicon'></BookIcon>
                    <span className='nav_title'>Courses</span>
                  </Link>
                )}

                {account_type === 'VENDOR' ? (
                  <Link
                    to='/instructor/courses'
                    className='nav-link d-flex align-items-center'
                  >
                    <BookIcon className='navicon'></BookIcon>
                    <span className='nav_title'>Courses</span>
                  </Link>
                ) : (
                  <Link
                    to='#'
                    onClick={() => settoggleNavmenu(!toggleNavmenu)}
                    className='nav-link'
                  >
                    <CategoryIcon className='navicon'></CategoryIcon>
                    <span className='nav_title'> Categories</span>
                  </Link>
                )}
                {account_type === 'VENDOR' ? (
                  <Link to='/instructor/reports' className='nav-link '>
                    <img src={reports} alt='reports' />
                    <span className='ml-1 nav_title'>Reports</span>
                  </Link>
                ) : (
                  <Link to='/cart' className='nav-link'>
                    <CartIcon className='navicon'></CartIcon>
                    <span className='nav_title'> Cart</span>
                    {cartTotal > 0 && (
                      <span className='ml-2 cart'>{cartItems.length}</span>
                    )}
                  </Link>
                )}
              </Nav>
              <Form inline>
                <SearchBar
                  IconClick={handleSearch}
                  btnIcon={SearchIcon}
                  type='text'
                  placeholder='Find something to learn '
                  handleBlur={(event: any) => handleBlur(event.target.value)}
                  onKeyPress={(event: any) => handleKeyPress(event)}
                />

                {isAuthenticated ? (
                  <>
                    <div className='mx-3'>
                      <BellIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          settoggleNotificationmenu(!toggleNotificationemenu);
                        }}
                      />
                    </div>

                    <AvatarProfileDropdown />
                  </>
                ) : (
                  <>
                    <Button
                      className='login_1'
                      onClick={() => {
                        setNavigationModal(true);
                        setAuthProcess('LOGIN');
                      }}
                    >
                      Login
                    </Button>
                    <Button
                      className='signin_1'
                      variant='outline-info'
                      onClick={() => {
                        setNavigationModal(true);
                        setAuthProcess('SIGNIN');
                      }}
                    >
                      Sign Up
                    </Button>
                  </>
                )}
              </Form>
            </Navbar.Collapse>
          </Navbar>
        ) : (
          <ResponsiveNavigationBar />
        )}
        <>
          {toggleNotificationemenu && (
            <NotificationMenu
              toggleNotificationemenu={toggleNotificationemenu}
            />
          )}
        </>

        <>{toggleCartmenu && <CartDropdown cartItems={cartItems} />}</>

        <> {toggleNavmenu && <NavMenu togglemenu={toggleNavmenu} />} </>

        <Modal
          display={navigationModal}
          closeModal={() => {
            setNavigationModal(false);
          }}
        >
          <AuthProcess
            banneremail={modaldetail?.email}
            authProcess={authProcess}
            setAuthProcess={setAuthProcess}
            display={navigationModal}
            closeModal={() => {
              setNavigationModal(false);
            }}
          />
        </Modal>
      </div>
    </section>
  );
};

export default NavigationBarComponent;
