import {
  GET_QUESTIONS,
  CREATE_QUESTION,
  QuestionAndAnswerTypes,
  createQuestionPayload,
} from 'store/types/questionTypes';
import { Dispatch } from 'redux';
import { sendRequest } from 'config/api';




/**
 *
 * @param lessonId
 *
 * getQuestions returns all the questions in a lesson.
 */
export const getQuestions = (lessonId: string) => async (
  dispatch: Dispatch<QuestionAndAnswerTypes>
) => {
  try {
    const { data }: any = await sendRequest(
      `/lms/lesson/${lessonId}/question/`
    );
    dispatch({
      type: GET_QUESTIONS,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const createQuestion = (question: createQuestionPayload, toast:any) => async (
  dispatch: Dispatch<QuestionAndAnswerTypes>
) => {
  try {
    const { data }: any = await sendRequest(
      `/lms/questions/`,
      question,
      'POST'
    );
    dispatch({
      type: CREATE_QUESTION,
      payload: data,
    });
    // toast.success("Question Added Sucessfully")
    return;
  } catch ({ response, message }) {
    
    return response?.data || message;
  }
};

export const createQuestionResponse = (question: createQuestionPayload, toast:any) => async (
  dispatch: Dispatch<QuestionAndAnswerTypes>
) => {
  try {
    const { data }: any = await sendRequest(
      `/lms/questions/`,
      question,
      'POST'
    );
    dispatch({
      type: CREATE_QUESTION,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    
    return response?.data || message;
  }
};
