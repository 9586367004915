import * as React from 'react';
import ErrorMessage from 'components/common/ErrorMessage';
import './Input.scss';

export interface InputProps {
  type?: string;
  handleChange?: any;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  label?: string;
  ref?: string;
  name?: string;
  textCounter?: number | string;
  required?: boolean;
  checked?: boolean;
  handleBlur?: any;
  className?: string;
  handleClick?: any;
  errorMessage?: any; // enforce this to be a string
  acceptedFiles?: string;
  startIcon?: any;
  endIcon?: any;
}

const Input: React.SFC<InputProps> = ({
  type = 'text',
  handleChange,
  disabled,
  value,
  placeholder,
  label,
  required = false,
  ref,
  name,
  textCounter,
  errorMessage = '',
  checked,
  handleBlur,
  handleClick,
  className = '',
  acceptedFiles,
  startIcon,
  endIcon,
}) => {
  return (
    <div className={`input-wrap ${className}`}>
      <div className='d-flex justify-content-between align-items-center'>
        {label && <label htmlFor={name}>{label}</label>}
        {textCounter && (
          <span
            className={`counter ${textCounter < 0 ? 'counter--negative' : ''}`}
          >
            {textCounter}
          </span>
        )}
      </div>
      
      <input
        type={type}
        ref={ref}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        required={required}
        name={name}
        id={name}
        checked={checked}
        onClick={handleClick}
        accept={acceptedFiles}
      />

      {startIcon && (
        <img src={startIcon} alt="" />
      )}
      {endIcon && (
        <img src={endIcon} alt="" />
      )}
      {errorMessage && (
        <ErrorMessage errorMessage={errorMessage} className='mt-2' />
      )}
      {/* {type === "radio" && (
        <>
          <span className='input__radio'></span>
          <span className='input__radio-inner'></span>
        </>
      )} */}
    </div>
  );
};

export default Input;
