//regular types
export const GET_WISHLIST = "GET_WISHLIST";

export type category = {
  id: string;
  assessments: string;
  name: string;
};

export type course = {
  id: string;
  title: string;
  author: string;
  cover: string | null;
  announcements: any[];
  modules: any[];
  projects: number | null;
  published?: boolean;
  category: category;
  /**
   * the line below allows for
   * other key-value pairs that might be on the
   * course object
   */
  [key: string]: any;
};

export interface WishlistInfoI {
  id?: string;
  course?: course;
  amount?: number;
  discount?: number;
  discount_type?: string;
  is_free?: boolean;
  [key: string]: any;
}

/**
 * the reducer below is the most important. It defines
 * the shape of the courseReducer...
 *
 * and should be updated accordingly
 */
export interface WishlistStateI {
  course: WishlistInfoI;
}

//TYPINGS FOR ACTIONS:
interface GetWishlistAction {
  type: typeof GET_WISHLIST;
  //   payload: WishlistInfoI;
}

export type WishlistActionTypes = GetWishlistAction;
