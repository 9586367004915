import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import jwt_decode from 'jwt-decode';
import store from './configureStore';
import { privateRoutes, publicRoutes } from './routes';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBar from 'components/common/NavigationBar/';
import Footer from 'components/common/Footer/';
import setAuthToken from 'helpers/setAuthToken';
import { ToastProvider } from './context/toastContext';
import HomePageLoader from 'components/common/HomePageLoader';
import 'react-toastify/dist/ReactToastify.css';

import {
  setCurrentUser,
  logout,
  setActiveRole,
} from 'store/actions/authActions';
import './App.scss';

// Check for token
if (localStorage.access) {
  // Set the auth token header auth
  setAuthToken(localStorage.access);
  // Decode token and get user info and exp
  const decoded: any = jwt_decode(localStorage.access);
  // Set user and isAuthenticated
  store.dispatch<any>(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch<any>(logout());
    // [TODO] Clear current
    // store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = '/';
  } else {
    if (localStorage.activeRole) {
      store.dispatch<any>(setActiveRole(localStorage.activeRole));
    }
  }
}

//Main app
function App() {
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<HomePageLoader />}>
          <div className="App">
            <ToastProvider>
              <NavigationBar />

              {/* <PrivateRoute  component={Courses}/>  */}

              <main className="main-page-content">
                <Switch>
                  {privateRoutes.map((route, index) => (
                    <PrivateRoute
                      path={route.url}
                      component={route.component}
                      key={index}
                      exact={route.exact}
                    />
                  ))}
                </Switch>
                <Switch>
                  {publicRoutes.map((route, index) => (
                    <Route
                      path={route.url}
                      component={route.component}
                      key={index}
                      exact
                    />
                  ))}
                </Switch>
              </main>
              <Footer />
            </ToastProvider>
          </div>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
