import * as React from 'react';
import './Login.scss';
import Button from '../../../common/Button';
import Input from '../../../common/Input';
import Logo from '../../../../assets/images/png/didemy_04.png';
import Google from '../../../../assets/images/svg/google-icon.svg';
import { useDispatch } from 'react-redux';
import { login } from 'store/actions/authActions';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { loginValidationSchema } from 'helpers/validations';
import ErrorMessage from 'components/common/ErrorMessage';
import { useToast } from 'context/toastContext';
import Modal from 'components/common/Modal';
import { setModalData } from 'store/actions';

export interface LoginProps {
  switchAuthProcessState: any;
  closeModal: any;
  display: any;
  banneremail?: any;
  setAuthProcess?: any;
}
interface SignUpValues {
  email: string;
  password: string;
}

const Login: React.SFC<LoginProps> = ({
  switchAuthProcessState,
  closeModal,
  display,
  banneremail,
  setAuthProcess,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const history = useHistory();
  const initialValues: SignUpValues = {
    email: banneremail ? banneremail : '',
    password: '',
  };

  const handleSubmit = async (data: any, { setSubmitting, resetForm }: any) => {
    const error = await dispatch(login(data, toast));
    if (!error) {
      resetForm();
      setSubmitting(false);
      closeModal();
      history.push('/');
      return;
    }
  };

  const handleClosemodal = async () => {
    const data = { email: '', status: false };
    setAuthProcess('');
    await dispatch(setModalData(data));
  };

  return (
    <Modal
      display={display}
      closeModal={() => {
        closeModal();
        handleClosemodal();
      }}
    >
      <div className='login'>
        <div className='login__intro d-flex flex-column align-items-center'>
          <img src={Logo} alt='logo' width='50px' />
          <h1 className='login__title'>Log in</h1>
          <p className='login__subtitle'>Welcome back to Didemy!</p>
        </div>
        <div className='d-flex flex-column  login__social'>
          <a href='https://dev.api.peerlesslms.com/api/v1/accounts/google/login'>
            <Button
              textContent='Sign in with Google'
              iconLeft={Google}
              buttonStyle='btn--secondary mb-2'
            />
          </a>
        </div>

        <div className='d-flex justify-content-between align-items-center my-4'>
          <hr className='login__line' />
          <span className='px-2 login__divider'>or</span>
          <hr className='login__line' />
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={yup.object({
            ...loginValidationSchema,
          })}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <Form>
              <div className='d-flex mb-3'>
                <Input
                  type='email'
                  required
                  name='email'
                  placeholder='Email'
                  value={values.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
              {errors.email && touched.email && (
                <ErrorMessage errorMessage={errors.email} />
              )}
              <div className='d-flex mb-3'>
                <Input
                  type='password'
                  required
                  name='password'
                  value={values.password}
                  placeholder='Password'
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
              {errors.password && touched.password && (
                <ErrorMessage errorMessage={errors.password} />
              )}

              <div className='d-flex flex-row my-4'>
                <Button
                  textContent='Log In'
                  buttonStyle='btn--default'
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type='submit'
                />
              </div>
            </Form>
          )}
        </Formik>

        <p className='login__forgot '>Forgot your password?</p>
      </div>
      <div className='login__footer'>
        <p className='login__signup-option'>
          Dont have an account?
          <span
            className='ml-1'
            role='button'
            tabIndex={0}
            onClick={() => switchAuthProcessState()}
          >
            Sign Up
          </span>
        </p>
      </div>
    </Modal>
  );
};

export default Login;
