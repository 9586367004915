import {
  GET_COURSE_INFO,
  CourseActionTypes,
  GET_CATEGORIES,
  GET_VENDOR_COURSES,
  GET_MY_COURSES,
  GET_MY_DRAFT_COURSES,
  GET_MY_ACTIVE_COURSES,
  GET_RECOMMENDED_COURSES,
  GET_COURSE_AUTHENTICATED_USER,
  GET_FREE_COURSES,
  GET_INTERESTED_COURSES,
  GET_POPULAR_COURSES,
  GET_FEATURED_COURSES,
  GET_VENDOR_DRAFT_COURSES,
  GET_VENDOR_ACTIVE_COURSES,
  CREATE_COURSE,
  UPDATE_COURSE,
} from 'store/types/courseTypes';
import { Dispatch } from 'redux';
import { handleLoading } from 'store/actions/loadingActions';
import { sendRequest } from 'config/api';

export const getCourse = (courseId: string) => async (
  dispatch: Dispatch<CourseActionTypes>
) => {
  try {
    const { data }: any = await sendRequest(`/lms/vendor-courses/${courseId}`);
    dispatch({
      type: GET_COURSE_INFO,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getCategories = () => async (dispatch: any) => {
  try {
    const { data }: any = await sendRequest('/lms/category/');
    dispatch(handleLoading());
    dispatch({
      type: GET_CATEGORIES,
      payload: data,
    });
    dispatch(handleLoading());
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getVendorCourses = (pageLink: string) => async (dispatch: any) => {
  try {
    let url = pageLink ? pageLink : '/lms/vendor-courses/mycourse/';
    const { data }: any = await sendRequest(`${url}`);
    dispatch(handleLoading());
    dispatch({
      type: GET_VENDOR_COURSES,
      payload: data,
    });
    dispatch(handleLoading());
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};
export const getVendorActiveCourses = (pageLink: string) => async (
  dispatch: any
) => {
  try {
    let url = pageLink
      ? pageLink
      : '/lms/vendor-courses/mycourse/?status=PUBLISHED';
    const { data }: any = await sendRequest(`${url}`);
    dispatch(handleLoading());
    dispatch({
      type: GET_VENDOR_ACTIVE_COURSES,
      payload: data,
    });
    dispatch(handleLoading());
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getVendorDraftCourses = (pageLink: string) => async (
  dispatch: any
) => {
  try {
    let url = pageLink
      ? pageLink
      : '/lms/vendor-courses/mycourse/?status=PENDING';
    const { data }: any = await sendRequest(`${url}`);
    dispatch(handleLoading());
    dispatch({
      type: GET_VENDOR_DRAFT_COURSES,
      payload: data,
    });
    dispatch(handleLoading());
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getMyCourses = () => async (dispatch: any) => {
  try {
    const { data }: any = await sendRequest('/lms/course/my_courses/');
    dispatch(handleLoading());
    dispatch({
      type: GET_MY_COURSES,
      payload: data,
    });
    dispatch(handleLoading());
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getMyActiveCourses = () => async (dispatch: any) => {
  try {
    const { data }: any = await sendRequest(
      '/lms/course/my_courses/?status=PUBLISHED'
    );
    dispatch(handleLoading());
    dispatch({
      type: GET_MY_ACTIVE_COURSES,
      payload: data,
    });
    dispatch(handleLoading());
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getMyDraftCourses = () => async (dispatch: any) => {
  try {
    const { data }: any = await sendRequest(
      '/lms/course/my_courses/?status=PENDING'
    );
    dispatch(handleLoading());
    dispatch({
      type: GET_MY_DRAFT_COURSES,
      payload: data,
    });
    dispatch(handleLoading());
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getCourseForAuthenticatedUser = (courseId: string) => async (
  dispatch: Dispatch<CourseActionTypes>
) => {
  try {
    const { data }: any = await sendRequest(
      `/lms/course/${courseId}/my_course/`
    );

    dispatch({
      type: GET_COURSE_AUTHENTICATED_USER,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getRecommendedCourses = () => async (
  dispatch: Dispatch<CourseActionTypes>
) => {
  try {
    const { data }: any = await sendRequest(
      '/lms/vendor-courses/recommended_courses/'
    );

    dispatch({
      type: GET_RECOMMENDED_COURSES,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getFeaturedCourses = () => async (
  dispatch: Dispatch<CourseActionTypes>
) => {
  try {
    const { data }: any = await sendRequest(
      '/lms/vendor-courses/featured_courses/'
    );

    dispatch({
      type: GET_FEATURED_COURSES,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getFreeCourses = () => async (
  dispatch: Dispatch<CourseActionTypes>
) => {
  try {
    const { data }: any = await sendRequest(
      '/lms/vendor-courses/free_courses/'
    );

    dispatch({
      type: GET_FREE_COURSES,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getInterestedCourses = () => async (
  dispatch: Dispatch<CourseActionTypes>
) => {
  try {
    const { data }: any = await sendRequest(
      '/lms/vendor-courses/interested_courses/'
    );

    dispatch({
      type: GET_INTERESTED_COURSES,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const getPopularCourses = () => async (
  dispatch: Dispatch<CourseActionTypes>
) => {
  try {
    const { data }: any = await sendRequest(
      '/lms/vendor-courses/popular_courses/'
    );

    dispatch({
      type: GET_POPULAR_COURSES,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const createCourse = (
  courseData: any,
  history: any,
  toast: any
) => async (dispatch: any) => {
  try {
    const { data }: any = await sendRequest('/lms/course/', courseData, 'POST');
    // const { data: patchData }: any = await sendRequest('/lms/vendor-courses/', {course: data.id}, 'POST');
    
    dispatch({
      type: CREATE_COURSE,
      payload: data,
    });
    toast.success('Course Created Successfully');
    history.push(`/instructor/courses/course/${data.id}/`);
    return;
  } catch ({ response, message }) {
    toast.error(response?.data || message);
    return response?.data || message;
  }
};

export const updateCourse = (
  courseData: any,
  courseId: string,
  toast: any
) => async (dispatch: any) => {
  try {
    const { data }: any = await sendRequest(
      `/lms/course/${courseId}/`,
      courseData,
      'PATCH'
    );
    dispatch({
      type: UPDATE_COURSE,
      payload: data,
    });
    toast.success('Course Updated Successfully');
    return;
  } catch ({ response, message }) {
    toast.error('An error occured');
    toast.error(response?.data || message);
    return response?.data || message;
  }
};
