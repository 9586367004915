import { DELETE_QUIZ } from './../types/quizTypes';

import {
  ADD_QUIZ,
  quizPayload,
  ADD_QUESTION_TO_QUIZ,
  GET_QUIZ_STATUS,
  GET_QUESTIONS_IN_AN_QUIZ,
  QUIZ_LOADING_HANDLER,
} from 'store/types/quizTypes';
import { getCourse } from 'store/actions/courseModuleActions';
import { sendRequest } from 'config/api';
import { toast } from 'react-toastify';

export const handleLoadingQuiz = () => ({
  type: QUIZ_LOADING_HANDLER,
});

export const addQuiz = (quiz: quizPayload, courseId: string) => async (
  dispatch: any
) => {
  try {
    const { data }: any = await sendRequest(`/assessment/`, quiz, 'POST');
    dispatch({
      type: ADD_QUIZ,
      payload: data,
    });
    dispatch(getCourse(courseId, toast));
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const addQuestionToQuiz = (quizID: string, question: any) => async (
  dispatch: any
) => {
  try {
    const { data }: any = await sendRequest(
      `/assessment/${quizID}/question/`,
      question,
      'POST'
    );
    dispatch({
      type: ADD_QUESTION_TO_QUIZ,
      payload: data,
    });
    dispatch(getQuestionsInAQuiz(quizID));
    return;
  } catch ({ response, message }) {
    return response?.data?.message || message;
  }
};

export const getQuestionsInAQuiz = (quizID: string) => async (
  dispatch: any
) => {
  try {
    const { data }: any = await sendRequest(`/assessment/${quizID}/questions/`);
    dispatch({
      type: GET_QUESTIONS_IN_AN_QUIZ,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    toast.error(JSON.stringify(response?.data?.message || message));
    return response?.data || message;
  }
};

export const editQuestionInAQuiz = (
  assessmentId: string,
  questionId: string,
  data: any,
  courseId?: string
) => async (dispatch: any) => {
  try {
    await sendRequest(
      `/assessment/${assessmentId}/question/${questionId}/`,
      data,
      'PATCH'
    );

    dispatch(getQuestionsInAQuiz(assessmentId));

    return;
  } catch ({ response, message }) {
    toast.error(JSON.stringify(response?.data || message));

    return response?.data || message;
  }
};

export const deleteQuestionFromAQuiz = (questionId: string) => async (
  dispatch: any
) => {
  try {
    await sendRequest(`/lms/questions/${questionId}/`, undefined, 'DELETE');

    toast.success('Question deleted successfully');
    window.location.reload();

    return;
  } catch ({ response, message }) {
    toast.error(JSON.stringify(response?.data || message));

    return response?.data || message;
  }
};

export const editQuiz = (
  quizID: string,
  quizInfo: any,
  courseId: string
) => async (dispatch: any) => {
  try {
    const { data }: any = await sendRequest(
      `/assessment/${quizID}/`,
      quizInfo,
      'PATCH'
    );
    await dispatch(getCourse(courseId, toast));
    return;
  } catch ({ response, message }) {
    return response?.data?.message || message;
  }
};

export const deleteQuiz = (quizID: string, moduleId: string) => async (
  dispatch: any
) => {
  try {
    const { data }: any = await sendRequest(
      `/assessment/${quizID}/`,
      undefined,
      'DELETE'
    );
    dispatch({
      type: DELETE_QUIZ,
      payload: {
        moduleId,
        quizID,
      },
    });
    toast.success('Delete succesffully');
    window.location.reload(); //this is a bad user experience...

    return;
  } catch ({ response, message }) {
    toast.error(JSON.stringify(response?.data?.message || message));
    return response?.data?.message || message;
  }
};

/**
 *
 * @getQuizStatus basically tells us if the user has started the quiz or not
 */
export const getQuizStatus = (quizID: string, moduleId: string) => async (
  dispatch: any
) => {
  try {
    dispatch(handleLoadingQuiz());
    const { data }: any = await sendRequest(
      `/assessment/${quizID}/status/${moduleId}/`
    );

    dispatch({
      type: GET_QUIZ_STATUS,
      payload: data,
    });
  } catch ({ response, message }) {
    toast.error(JSON.stringify(response?.data?.message || message));
  }
  dispatch(handleLoadingQuiz());
};

export const startQuiz = (quizID: string, moduleId: string) => async (
  dispatch: any
) => {
  try {
    dispatch(handleLoadingQuiz());
    const { data }: any = await sendRequest(
      `/assessment/${quizID}/start/`,
      {
        module: moduleId,
      },
      'POST'
    );
    // dispatch(getQuizStatus(quizID,))
  } catch ({ response, message }) {
    toast.error(JSON.stringify(response?.data?.message || message));
  }
  dispatch(handleLoadingQuiz());
};
