import * as React from "react";
import "./index.scss";

export interface InputProps {
  type: string;
  handleChange?: any;
  disabled?: boolean;
  value?: string;
  placeholder?: string;
  label?: string;
  ref?: string;
  name?: string;
  textCounter?: number;
  required?: boolean;
  checked?: boolean;
  handleBlur?: any;
  btnIcon?:any;
  onKeyPress?: any;
  IconClick?: any;
}

const SearchBar: React.SFC<InputProps> = ({
  type,
  handleChange,
  disabled,
  value,
  placeholder,
  label,
  required = false,
  ref,
  name,
  textCounter,
  checked,
  handleBlur,
  btnIcon,
  onKeyPress,
  IconClick,
}) => {
  return (
    <>
      <div className='searchbar d-flex justify-content-between'>
        {label && <label htmlFor={name}>{label}</label>}
        {textCounter && <span>{textCounter}</span>}
     
     
  <button type="button" onClick={IconClick}>{btnIcon}</button>
 <input
        type={type}
        ref={ref}
        onBlur={handleBlur}
        onKeyPress={onKeyPress}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        required={required}
        name={name}
        checked={checked}
      />
  </div>
    </>
  );
};

export default SearchBar;
