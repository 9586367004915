//types
export const ADD_QUIZ = 'ADD_QUIZ';
export const ADD_QUESTION_TO_QUIZ = 'ADD_QUESTION_TO_QUIZ';
export const GET_QUIZ = 'GET_QUIZ';
export const GET_QUESTIONS_IN_AN_QUIZ = 'GET_QUIZ';
export const DELETE_QUIZ = 'DELETE_QUIZ';
export const GET_QUIZ_STATUS = 'GET_QUIZ_STATUS';
export const QUIZ_LOADING_HANDLER = 'QUIZ_LOADING_HANDLER';

//typings and interface
//quiz payload comes in here...
export interface quizPayload {
  title: string;
  modules: string[];
  [key: string]: any;
}

export interface IAddQuiz {
  type: typeof ADD_QUIZ;
}

export interface IQuestion {
  type: typeof ADD_QUESTION_TO_QUIZ;
}

export interface IGetQuiz {
  //get's a single quiz
  type: typeof GET_QUIZ;
}

export type QuizActionTypes = IAddQuiz | IGetQuiz;
