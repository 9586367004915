import moduleTypes from "store/types/courseModuleTypes";
import {DELETE_QUIZ} from 'store/types/quizTypes'
const {
  GET_MODULE,
  ADD_MODULE,
  EDIT_MODULE,
  DELETE_MODULE,
  EDIT_LESSON,
  ADD_LESSON,
} = moduleTypes;

const initialState = {
  course: {modules:[]},
  isEditing: false,
  moduleInEditing: null,
  lessonInEditing: null,
  courseId: "",
  isEditingLesson: false,
};

export default function courseModuleReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case GET_MODULE:
      return {
        ...state,
        course: payload.course,
        courseId: payload.courseId,
      };
    case ADD_MODULE:
      return {
        ...state,
        modules: [...state.course.modules, payload],
      };
    case EDIT_MODULE:
      const removedStaleModule = state.course.modules.filter(
        (eachModule: any) => eachModule.id !== payload.moduleId
      );
      return {
        ...state,
        editing: false,
        modules: [...removedStaleModule, payload.moduleData],
        moduleInEditing: null,
      };
    case DELETE_MODULE:
      return {
        ...state,
        modules: [state.course.modules].filter(
          (eachModule: any) => eachModule.id !== payload.moduleId
        ),
      };
      case ADD_LESSON:
        //find module that we want to add lessons to
        const updatedModule: any = state.course.modules.find(
          (eachModule: any) => eachModule.id === payload.moduleId
        );
        //add new lesson to the module
        updatedModule.lessons.push(payload.newLesson);
        //remove stale module from modules list
        const staleModule: any = state.course.modules.filter(
          (eachModule: any) => eachModule.id !== payload.moduleId
        );
        //create new updated module
        const newModules = [...staleModule, updatedModule];
        return {
          ...state,
          modules: newModules,
        };

    case EDIT_LESSON:
      //find module has the lessonId
      const targetModule: any = state.course.modules.find((eachModule: any) =>
        eachModule.lessons.includes(payload.lessonId)
      );
      //remove staleLesson from targetModule
      const staleLesson: any = targetModule.lessons.filter(
        (eachLesson: any) => eachLesson !== payload.lessonId
      );
      //create updated lesson for target module
      const newTargetModuleLesson = [...staleLesson, payload.updatedLesson];
      //update targetModule
      targetModule.lessons = newTargetModuleLesson;

      //create updated modules
      //first remove the stale module
      const staleModules: any = state.course.modules.filter(
        (eachModule: any) => eachModule.id !== targetModule.id
      );

      //then create  new modules
      const updatedModules: any = [...staleModules, targetModule];
      return {
        ...state,
        modules: updatedModules,
      };
      
    default:
      return state;
  }
}
