import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { ReactComponent as CloseIcon } from 'assets/images/svg/close.svg';
import { ReactComponent as BookIcon } from 'assets/images/svg/courses.svg';
import { ReactComponent as CategoryIcon } from 'assets/images/svg/category.svg';
import { ReactComponent as CartIcon } from 'assets/images/svg/cart.svg';
import { ReactComponent as BellIcon } from 'assets/images/svg/bell.svg';
import { reports } from 'assets/images/svg';
import LogoIcon from 'assets/images/png/didemy06.png';
import Avatar from 'components/common/Avatar';
import SearchBar from 'components/common/SearchButton';
import { getCategories, getVendorCourses } from 'store/actions';
import './index.scss';
import AuthProcess from 'components/layout/AuthProcess';
import NavMenu from 'components/common/NavigationBar/DropdownMeun';
import ProfileDropdown from 'components/common/NavigationBar/ProfileDropdown';
import CartDropdown from 'components/common/NavigationBar/CartDropdown';
import NotificationMenu from 'components/common/NavigationBar/Notification';
import Modal from 'components/common/Modal';
import { getAll } from 'store/actions/cartActions';
import { useToast } from 'context/toastContext';
import './index.scss';

interface Props {
  expanded?: boolean;
}

const ResponsiveNavigationBarComponent: React.FC<Props> = () => {
  const { modaldetail } = useSelector((state: any) => state.auth);
  const [navigationModal, setNavigationModal] = useState(false);
  const [toggleNavmenu, settoggleNavmenu] = useState(false);
  const [toggleProfilemenu, settoggleProfilemenu] = useState(false);
  const [toggleNotificationemenu, settoggleNotificationmenu] = useState(false);
  const [toggleCartmenu, settoggleCartmenu] = useState(false);
  let [courseSearchValue, setcourseSearchValue] = useState('');
  const [expanded, setExpanded] = React.useState(false);
  const toast = useToast();

  const { isAuthenticated, user } = useSelector((state: any) => state.auth);
  const { image, firstname, lastname } = useSelector(
    (state: any) => state.auth.user
  );
  const { cartItems } = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();
  // Auth Process can either be Login or SignUp
  const [authProcess, setAuthProcess] = useState('');
  let SearchIcon = <i className='fa fa-search'></i>;
  let url: any;

  useEffect(() => {
    fetchCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCart = () => {
    dispatch(getAll(toast));
  };
  // let enter: boolean = true

  useEffect(() => {
    setAuthProcess('');
    if (modaldetail?.status) {
      setNavigationModal(true);
      setAuthProcess('SIGNIN');
    }
  }, [modaldetail]);

  useEffect(() => {
    fetchCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const fetchCategories = async () => {
    await dispatch(getCategories());
    await dispatch(getVendorCourses(url));
  };

  const handleKeyPress = (event: any) => {
    let searchvalue = event.target.value;

    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.target.value !== '') {
        dispatch(
          getVendorCourses(`/lms/vendor-courses/?search=${searchvalue}`)
        );
      } else {
        setcourseSearchValue('');
        dispatch(getVendorCourses(url));
      }
    }
  };

  const handleSearch = () => {
    if (courseSearchValue !== '') {
      dispatch(
        getVendorCourses(`/lms/vendor-courses/?search=${courseSearchValue}`)
      );
    }
  };

  const handleBlur = (value: string) => {
    if (value !== '') {
      setcourseSearchValue(value);
    } else {
      setcourseSearchValue('');
      dispatch(getVendorCourses(url));
    }
  };

  return (
    <>
      <Navbar expand='lg' bg='dark' variant='dark' className='bg_nav'>
        <Navbar.Brand href='/' className='mx-auto order-0'>
          <img src={LogoIcon} alt='Logo' />
        </Navbar.Brand>
        {!expanded ? (
          <Navbar.Toggle
            aria-controls='basic-navbar-nav'
            className='order-first'
            onClick={() => setExpanded(expanded ? false : true)}
          />
        ) : (
          <Navbar.Toggle
            className='order-first'
            aria-controls='basic-navbar-nav'
          >
            <span>
              <CloseIcon
                onClick={() => setExpanded(!expanded ? true : false)}
              ></CloseIcon>
            </span>
          </Navbar.Toggle>
        )}

        {isAuthenticated ? (
          <>
            <span className='nav-link'>
              <CartIcon
                className='navicon'
                onClick={() => {
                  settoggleCartmenu(!toggleCartmenu);
                }}
              ></CartIcon>
              <Link to='/cart' className='cart_link'>
                <span className='nav_title'> Cart</span>
                {cartItems.length > 0 && (
                  <span className='ml-2 cart'>{cartItems.length}</span>
                )}
              </Link>
            </span>
            <>{toggleCartmenu && <CartDropdown cartItems={cartItems} />}</>
            <div className='mx-3'>
              <BellIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  settoggleNotificationmenu(!toggleNotificationemenu);
                }}
              />
              <>
                {toggleNotificationemenu && (
                  <NotificationMenu
                    toggleNotificationemenu={toggleNotificationemenu}
                  />
                )}
              </>
            </div>
            <Avatar
              firstName={firstname}
              lastName={lastname}
              image={image}
              onClick={() => {
                settoggleProfilemenu(!toggleProfilemenu);
              }}
            />

            <>
              {toggleProfilemenu && (
                <ProfileDropdown
                  toggleProfilemenu={toggleProfilemenu}
                  user={user}
                />
              )}
            </>
          </>
        ) : (
          <Nav
            className='media_nav_link'
            onClick={() => {
              setNavigationModal(true);
              setAuthProcess('LOGIN');
            }}
          >
            <Nav.Link href='#home'>
              <span className='nav_title'>Log in</span>
            </Nav.Link>
          </Nav>
        )}
        <Navbar.Collapse id='basic-navbar-nav'>
          <Form inline>
            <SearchBar
              IconClick={handleSearch}
              btnIcon={SearchIcon}
              type='text'
              placeholder='Find something to learn '
              handleBlur={(event: any) => handleBlur(event.target.value)}
              onKeyPress={(event: any) => handleKeyPress(event)}
            />
          </Form>
          {user?.account_type === 'LEARNER' && (
            <Nav className='mr-auto mx-auto '>
              <div className='nav_list_wrapper'>
                <Link to='/courses' className='nav-link'>
                  <BookIcon className='navicon'></BookIcon>
                  <span className='nav_title'> Courses</span>
                </Link>
                <div className='nav_with_dropdown'>
                  <Link
                    to='#'
                    onClick={() => settoggleNavmenu(!toggleNavmenu)}
                    className='nav-link'
                  >
                    <CategoryIcon className='navicon'></CategoryIcon>

                    <span className='nav_title'> Categories</span>
                    <CloseIcon className='navicon mr-auto close_category'></CloseIcon>
                  </Link>
                  <>
                    {' '}
                    {toggleNavmenu && (
                      <NavMenu togglemenu={toggleNavmenu} />
                    )}{' '}
                  </>
                </div>
                {!isAuthenticated && (
                  <Link to='/cart' className='nav-link'>
                    <CartIcon className='navicon'></CartIcon>
                    <span className='nav_title'> Cart</span>
                  </Link>
                )}
              </div>
            </Nav>
          )}
          {user?.account_type === 'VENDOR' && (
            <Nav className='mr-auto mx-auto '>
              <div className='nav_list_wrapper'>
                <Link to='/instructor/courses' className='nav-link'>
                  <BookIcon className='navicon'></BookIcon>
                  <span className='nav_title'> Courses</span>
                </Link>
                <div className='nav_with_dropdown'>
                  <Link
                    to='/instructor/reports'
                    onClick={() => settoggleNavmenu(!toggleNavmenu)}
                    className='nav-link'
                  >
                    <img src={reports} alt='reports' />

                    <span className='nav_title'> Reports</span>
                  </Link>
                </div>
                {!isAuthenticated && (
                  <Link to='/cart' className='nav-link'>
                    <CartIcon className='navicon'></CartIcon>
                    <span className='nav_title'> Cart</span>
                  </Link>
                )}
              </div>
            </Nav>
          )}
          {!isAuthenticated && (
            <Form inline>
              <Button className='signin_1' variant='outline-info'>
                Sign up
              </Button>
            </Form>
          )}
        </Navbar.Collapse>
      </Navbar>

      <Modal
        display={navigationModal}
        closeModal={() => {
          setNavigationModal(false);
        }}
      >
        <AuthProcess
          banneremail={modaldetail?.email}
          authProcess={authProcess}
          setAuthProcess={setAuthProcess}
          display={navigationModal}
          closeModal={() => {
            setNavigationModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default ResponsiveNavigationBarComponent;
