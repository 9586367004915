export const ADD_ASSIGNMENT = 'ADD_ASSIGNMENT'

//payload interface
export  interface IAssignmentPayload {
  instruction: string,
  details: string,
  [key:string]:any
}

//TYPINGS FOR ACTIONS:
interface IAddAssignmentAction {
  type: typeof ADD_ASSIGNMENT,
  
}

export type AssignmentActonTypes = IAddAssignmentAction