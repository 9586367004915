import { sendRequest } from "config/api";
import cartTypes from "../types/cartTypes";

const {
  ADD_ITEM,
  REMOVE_ITEM,
  VERIFY_COUPON_CODE,
  GET_CART_SUCCESS,
  // ADD_WISHLIST,
  // REMOVE_WISHLIST,
  // CHECK_OUT,
  DELETE_COUPON,
  LOADING,
  CLEAR,
  ORDER_SUCCESS,
  ORDER_FAILURE,
} = cartTypes;

export const getAll = (toast) => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: {// the payload here is not necessary though
      loading: true,
    },
  });
  try {
    const { data: results } = await sendRequest(`/marketplace/cart/`);
    dispatch({
      type: GET_CART_SUCCESS,
      payload: {
        cartItems: results.results,
        cartTotal: results.total,
        loading: false,
      },
    });
  } catch ({ response, message }) {
    dispatch({
      type: LOADING,
      payload: {
        loading: false,
      },
    });
    toast.error(response?.data || message)
    return response?.data || message;
  }
};

export const deleteItem = (courseId, cartItems, toast) => async (dispatch) => {
  try {
    await sendRequest(`/marketplace/cart/${courseId}`, null, "DELETE");
    toast.success("successfully deleted item");
    dispatch(getAll());
    dispatch({
      type: REMOVE_ITEM,
      payload: {
        cartItems: cartItems,
        courseId,
      },
    });
    return;
  } catch ({ response, message }) {
    toast.error("unable to delete item");
    return response?.data || message;
  }
};

export const failureModal = (resultType) => async(dispatch) => {
  dispatch({
    type: ORDER_FAILURE,
    payload: {
      failure: resultType
    }
   });
}

export const closeSuccessModal = () => async(dispatch) => {
  dispatch({
    type: ORDER_SUCCESS,
    payload: {
      success: false,
      loading: false,
    }
   });
   dispatch({
     type: CLEAR,
   })
}

export const verifyPayment = (verificationData, toast) => async (dispatch) => {
  try {
    await sendRequest(`/marketplace/order/payment/verify/`,verificationData,'POST');
   dispatch({
    type: ORDER_SUCCESS,
    payload: {
      success: true
    }
   });
  } catch ({ response, message }) {
    dispatch({
      type: ORDER_FAILURE,
      payload: {
        failure: true
      }
    })
    toast.error(response?.data?.message || message);
    return response?.data || message;
  }
}

export const verifyCoupon = (couponData) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/marketplace/order/coupon_check/`,
      couponData,
      "POST"
    );
    dispatch({
      type: VERIFY_COUPON_CODE,
      payload: {
        coupon: data.coupon_code,
      },
    });
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const deleteCoupon = () => (dispatch) => {
  dispatch({
    type: DELETE_COUPON,
    payload: {
      coupon: "",
    },
  });
};

export const checkout = (checkoutData,reference,  toast) => async (dispatch) => {
  try {
    const {data} = await sendRequest(`/marketplace/order/checkout/`, checkoutData, "POST");
    const verificationData = {
      order: data.id,
      reference: reference,
      payment_method: "PAYSTACK",
    }
    dispatch(verifyPayment(verificationData, toast));
  } catch ({ response, message }) {
    toast.error(response?.data?.message || message);
    return response?.data || message;
  }
};

export const clearCart = () => async (dispatch) => {
  dispatch({
    type: CLEAR,
  });
};

export const addWishList = (wishlistData, toast, itemId) => async (dispatch) => {
  try {
    await sendRequest(`/marketplace/wishlist/`, wishlistData, "POST");
    if(itemId) {
      await sendRequest(
        `/marketplace/cart/${itemId}`,
        null,
        "DELETE"
      );
    }
    toast.success("Added to wishlist successfully");
    dispatch(getAll())
  } catch ({ response, message }) {
    toast.error(response?.data || message);
    return response?.data || message;
  }
};

export const removeWishList = (wishlistId, toast) => async (dispatch) => {
  try {
    await sendRequest(`marketplace/wishlist/${wishlistId}`, null, "DELETE");
    toast.success("Removed from wishlist Successfuly");
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const addToCart = (currentCart, courseData, courseId, toast) => async (
  dispatch
) => {
  try {
    await sendRequest(`/marketplace/cart/`, { course: courseId }, "POST");
    toast.success("successfully added to cart");
    dispatch({
      type: ADD_ITEM,
      payload: {
        cartItems: [...currentCart, courseData],
      },
    });
     dispatch(getAll());
     return
  } catch ({ response, message }) {
    toast.error(response?.data?.message || message);
    return response?.data || message;
  }
};
