import axios from "axios";
import store from '../configureStore'

export const baseUrl = process.env.REACT_APP_BASE_URL || 'https://dev.api.peerlesslms.com/api/v1';


//woud be cleaned up
export const sendRequest = (
  endpoint,
  data = {},
  method = "GET",
  contentType = "application/json",
  dispatch
) => {
  try {
    return axios({
      url: baseUrl+endpoint,//`${baseUrl}${endpoint}`,
      method: method,
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-type': contentType,
        //this below might no longer be needed if the login in processs appends the token to the header already
        Authorization: localStorage.getItem('access')
          ? `Bearer ${localStorage.getItem('access')}`
          : '',
      },
      onUploadProgress: progressEvent => {
        const uploadedPercent =  parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        )
        if(dispatch) dispatch({type:'IS_UPLOADING', payload:{percent: uploadedPercent}})
      }
    });
  } catch (error) {
    console.log(error.message);
  }
};
