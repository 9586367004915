import * as React from "react";
import "./index.scss";
import LogoIcon from "assets/images/png/didemy_02.png";
import { ReactComponent as FaceBookIcon } from "assets/images/svg/facebook.svg";
import { ReactComponent as TwitterIcon } from "assets/images/svg/twitter.svg";
import { NavLink } from "react-router-dom";

interface Props {}

const FooterComponent: React.FC<Props> = () => {
  return (
    <>
      <footer>
        <div className="container wider_container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer_logo_icon  f-one ">
                <img src={LogoIcon} className="main_logo" alt="Logo" />
                <p>
                  Copyright © {new Date().getFullYear()} Zone Tech Park. All
                  rights reserved.
                </p>{" "}
              </div>
            </div>

            <div className="col-md-4 f-2">
              <div className="">
                <span className="footer-social-icon">
                  <FaceBookIcon></FaceBookIcon>
                </span>
                <span className="footer-social-icon">
                  <TwitterIcon></TwitterIcon>
                </span>
                <span className="footer-social-icon">
                  <FaceBookIcon></FaceBookIcon>
                </span>
              </div>
            </div>

            <div className="col-md-4 f-3">
              <div className="footer_logo_icon ">
                <NavLink className="text-decoration-none" to="/support">
                  <p>Support Center</p>
                </NavLink>

                <NavLink className="text-decoration-none" to="/about-us">
                  <p>About</p>
                </NavLink>
                <NavLink
                  className="text-decoration-none"
                  to={`/privacy${"&"}terms`}
                >
                  <p>Privacy {"&"} Terms</p>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterComponent;
