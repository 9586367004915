import React from 'react';
import Button from 'components/common/Button';

export interface IEmptyStateProps {
  src: string;
  textContent: string;
  className?: string;
  handleClick?: Function;
  children?: React.ReactNode;
  iconRight?: string;
  iconLeft?: string;
  buttonStyle?: string;
}

export default function EmptyState({
  src,
  textContent,
  className = '',
  handleClick,
  iconLeft,
  iconRight,
  buttonStyle,
  children,
}: IEmptyStateProps) {
  return (
    <div className={`empty-state-pwa d-flex flex-column ${className}`}>
      <img src={src} alt={textContent} />
      {textContent && (
        <Button
          textContent={textContent}
          handleClick={handleClick}
          buttonStyle={`mt-4 ${buttonStyle}`}
          iconLeft={iconLeft}
          iconRight={iconRight}
        />
      )}
      {children}
    </div>
  );
}
