import React from "react";
import "./Notification.scss";
// import { useDispatch } from "react-redux";
// import { Link, useHistory } from "react-router-dom";
import Avatar from "components/common/Avatar";
import EmptyState from "components/common/EmptyState"
import { notificationIcon } from "assets/images/svg";

interface Props {
 toggleNotificationemenu?: any;
  user?: any;
}

const Notify: React.FC<Props> = ({ toggleNotificationemenu, user }) => {
  let notifytotal: number=0;  
  const handleMarkAll = () => {};
  return (
    <>
      <div className="notification_wrapper">
        <div className="notify_header">
          <h1>Notifications</h1>
        </div>

        {notifytotal !== 0 ? 
          <EmptyState
            src={notificationIcon}
            textContent={"No notifications yet"}
            className={"announcement"}
            handleClick={() => {}}
          />
        :
        <>
        <div className="overflow_wrapper">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: any, index) => (
            <div className="notify_content">
              <Avatar firstName={"Daniel"} image={""} />
              <div className="notify_text">
                <span className="span_text">
                  Timothy Akala just shared a new photography course
                  Introduction to Narative Photography{" "}
                </span>
                <span className="span_time">12 minutes ago</span>
              </div>
            </div>
          ))}
        </div>
        <div className="notify_footer">
          <h1 onClick={handleMarkAll}>Mark all as read</h1>
        </div>
        </>
        }
      </div>
    </>
  );
};

export default Notify;
