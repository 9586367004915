import { GET_LESSON } from 'store/types/lessonTypes';
import {handleLoading} from 'store/actions'
import {sendRequest} from 'config/api'

export const getLesson = (lessonId:string) => async (dispatch:any) => {
    try {
      dispatch(handleLoading());
      const { data }:any = await sendRequest(`/lms/lesson/${lessonId}/`);  
      dispatch({
        type: GET_LESSON,
        payload: data,
      });
      dispatch(handleLoading());
    } catch ({response, message}) {
      dispatch(handleLoading());
      return response?.data || message;
    }
  };
