//regular types
export const GET_COURSE_INFO = 'GET_COURSE_INFO';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_VENDOR_COURSES = 'GET_VENDOR_COURSES';
export const GET_MY_COURSES = 'GET_MY_COURSES';
export const GET_MY_ACTIVE_COURSES = 'GET_MY_ACTIVE _COURSES';
export const GET_MY_DRAFT_COURSES = 'GET_MY_DRAFT_COURSES';
export const GET_COURSE_AUTHENTICATED_USER = 'GET_COURSE_AUTHENTICATED_USER';
export const GET_RECOMMENDED_COURSES = 'GET_RECOMMENDED_COURSES';
export const GET_FREE_COURSES = 'GET_FREE_COURSES';
export const GET_INTERESTED_COURSES = 'GET_INTERESTED_COURSES';
export const GET_POPULAR_COURSES = 'GET_POPULAR_COURSES';
export const GET_FEATURED_COURSES = 'GET_FEATURED_COURSES';
export const GET_VENDOR_ACTIVE_COURSES = 'GET_VENDOR_ACTIVE_COURSES';
export const GET_VENDOR_DRAFT_COURSES = 'GET_VENDOR_DRAFT_COURSES';
export const CREATE_COURSE = 'CREATE_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';

// would have like a course interface
export type category = {
  id: string;
  assessments: string;
  name: string;
};

export interface CategoriesInfo {
  created_at: string;
  id: number;
  name: string;
  modified_at: string;
  [key: string]: any;
}

export type course = {
  id: string;
  title: string;
  author: string;
  cover: string | null;
  announcements: any[];
  modules: any[];
  projects: number | null;
  published?: boolean;
  category: category;
  /**
   * the line below allows for
   * other key-value pairs that might be on the
   * course object
   */
  [key: string]: any;
};

//and courseInformation interface
export interface CourseInfoI {
  id?: string;
  course?: course;
  amount?: number;
  discount?: number;
  discount_type?: string;
  is_free?: boolean;
  [key: string]: any;
}

/**
 * the reducer below is the most important. It defines
 * the shape of the courseReducer...
 *
 * and should be updated accordingly
 */

/**
 * the reducer below is the most important. It defines
 * the shape of the courseReducer...
 *
 * and should be updated accordingly
 */
export interface CourseStateI {
  course: CourseInfoI;
  createdCourse: CourseInfoI;
  categories: CategoriesInfo[];
  vendorCourses: CourseInfoI;
  vendorActiveCourses: CourseInfoI;
  vendorDraftCourses: CourseInfoI;
  myCourses: CourseInfoI[];
  myDraftCourses: CourseInfoI[];
  myActiveCourses: CourseInfoI[];
  myCourse?: any;
  recommendedcourses?: CourseInfoI;
  freeCourse: CourseInfoI;
  featuredCourses: CourseInfoI;
  popularCourses: CourseInfoI;
  interestedCourses: CourseInfoI;
}

//TYPINGS FOR ACTIONS:
interface GetCourseAction {
  type: typeof GET_COURSE_INFO;
  //   payload: CourseInfoI;
}

interface GetCategories {
  type: typeof GET_CATEGORIES;
}
interface GetVendorCourses {
  type: typeof GET_VENDOR_COURSES;
}
interface GetVendorActiveCourses {
  type: typeof GET_VENDOR_ACTIVE_COURSES;
}
interface GetVendorDraftCourses {
  type: typeof GET_VENDOR_DRAFT_COURSES;
}
interface GetMyCourses {
  type: typeof GET_MY_COURSES;
}
interface GetMyActiveCourses {
  type: typeof GET_MY_ACTIVE_COURSES;
}
interface GetMyDraftCourses {
  type: typeof GET_MY_DRAFT_COURSES;
}

interface IGetCourseAuthenticatedUser {
  type: typeof GET_COURSE_AUTHENTICATED_USER;
}
interface GetRecommendedCourse {
  type: typeof GET_RECOMMENDED_COURSES;
}
interface GetFreeCourse {
  type: typeof GET_FREE_COURSES;
}

interface GetPopularCourse {
  type: typeof GET_POPULAR_COURSES;
}

interface GetInterestedCourse {
  type: typeof GET_INTERESTED_COURSES;
}

interface GetFeaturedCourse {
  type: typeof GET_FEATURED_COURSES;
}
interface CreateCourse {
  type: typeof CREATE_COURSE;
}
interface UpdateCourse {
  type: typeof UPDATE_COURSE;
}

export type CourseActionTypes =
  | GetCourseAction
  | GetCategories
  | GetVendorCourses
  | GetMyCourses
  | IGetCourseAuthenticatedUser
  | GetRecommendedCourse
  | GetFeaturedCourse
  | GetInterestedCourse
  | GetPopularCourse
  | GetFreeCourse
  | GetVendorActiveCourses
  | GetVendorDraftCourses
  | CreateCourse
  | UpdateCourse
  | GetMyActiveCourses
  | GetMyDraftCourses;
