import React from "react";
import MenuBackgrounIcon from "assets/images/svg/menubackground.svg";
import "./index.scss";
import "./DropdownMenu.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useComponentVisible, useWindowSize } from "helpers/customHooks";


interface Props {
  togglemenu?: any;
}

const NavMenu: React.FC<Props> = ({ togglemenu }) => {
  const { categories } = useSelector((state: any) => state.course);
  const width = useWindowSize();

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(true);

  const chunkArrayInGroups = (arr: any, size: any) => {
    var myArray = [];
    for (var i = 0; i < arr.length; i += size) {
      myArray.push(arr.slice(i, i + size));
    }
    return myArray;
  };

  return (
    <>
      {isComponentVisible && (
        <div
          onClick={() => setIsComponentVisible(!isComponentVisible)}
          ref={ref}
          className={"dropdown-content navmenu animate"}
          style={{ backgroundImage: width > 998 ?  `url(${MenuBackgrounIcon})` : ''}}
        >
          <table>
            <tr className="nav_title">
              {chunkArrayInGroups(categories, 20).map(
                (category, index: any) => (
                  <span key={index}>
                    {category.map((item: any, categoryIndex: number) => (
                      <td key={categoryIndex}>
                        <Link to={`/courses/${item.name}`}>{item.name} </Link>
                      </td>
                    ))}
                  </span>
                )
              )}
            </tr>
          </table>
        </div>
      )}
    </>
  );
};

export default NavMenu;
