import {
  ADD_STRUCTURE,
  CREATE_FREE_PLAN,
  CREATE_ONETIME_PLAN,
  CREATE_BULK_PLAN,
  GET_PRICINGS,
  SELECT_PLAN,
  CLOSE,
  FREE_PLAN,
  ONETIME_PLAN,
  EDIT_PLAN,
  EDIT_FREEPLAN,
  DELETE_PRICING,
  EDIT_ONETIME,
} from 'store/types/pricingTypes';

const initialState = {
  nextForm: null,
  previousForm: null,
  pricings: [],
  selectedPlan: null,
  planDetails: null,
  editing: false,
};

const pricingReducer: any = (
  state: any = initialState,
  { type, payload }: any
) => {
  switch (type) {
    case ADD_STRUCTURE:
      return {
        ...state,
        nextForm: payload.plan,
      };
    case CREATE_FREE_PLAN:
      return {
        ...state,
        pricings: [
          ...state.pricings,
          {
            ...payload,
            plan_alias: 'Free plan',
          },
        ],
      };
    case CREATE_ONETIME_PLAN:
      return {
        ...state,
        pricings: [
          ...state.pricings,
          {
            ...payload,
            plan_alias: 'One-time purchase',
          },
        ],
      };
    case CREATE_BULK_PLAN:
      return {
        ...state,
        pricings: [
          ...state.pricings,
          {
            ...payload,
            plan_alias: 'Bulk purchase',
          },
        ],
      };
    case SELECT_PLAN:
      return {
        ...state,
        selectedPlan: payload.type,
      };
    case GET_PRICINGS:
      return {
        ...state,
        pricings: [...payload.results],
      };
    case CLOSE:
      return {
        ...state,
        nextForm: null,
        editing: false,
      };
    case EDIT_PLAN:
      return {
        ...state,
        planDetails: payload.pricingDetails,
        editing: true,
      };
    case EDIT_FREEPLAN:
      const staleFreePlan = state.pricings.filter((eachPricing: any) => {
        return eachPricing.structure !== payload.planType;
      });

      const newPricings = [
        ...staleFreePlan,
        {
          name: payload.name,
          structure: FREE_PLAN,
          plan_alias: 'Free plan',
          discount: null,
          amount: 0,
        },
      ];
      return {
        ...state,
        pricings: newPricings,
        planDetails: null,
        editing: false,
      };
    case DELETE_PRICING:
      const filteredPricings: any = state.pricings.filter(
        (eachPricing: any) => eachPricing.structure !== payload.planType
      );
      return {
        ...state,
        pricings: filteredPricings,
      };
    case EDIT_ONETIME:
      const staleOneTimePlan = state.pricings.filter((eachPricing: any) => {
        return eachPricing.structure !== payload.structure;
      });

      const updatedPricings = [
        ...staleOneTimePlan,
        {
          discount: payload.discount,
          currency: payload.currency,
          amount: payload.amount,
          discount_start: payload.discount_start,
          discount_end: payload.discount_end,
          name: payload.name,
          structure: ONETIME_PLAN,
          plan_alias: 'One-time purchase',
        },
      ];
      return {
        ...state,
        pricings: updatedPricings,
        planDetails: null,
        editing: false,
      };
    default:
      return state;
  }
};

export default pricingReducer;
