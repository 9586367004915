import React from "react";
import { ToastContainer } from "react-toastify";

import CustomToast from "components/common/Toast/CustomToast";
// import CheckMark from "components/common/Toast/CheckMark";
// import CloseToast from "components/common/Toast/CloseToast";

const ToastContext = React.createContext();

function ToastProvider(props) {
  return (
    <ToastContext.Provider value={CustomToast} {...props}>
      <ToastContainer autoClose={5000} position="bottom-left" />
      {props.children}
    </ToastContext.Provider>
  );
}

const useToast = () => React.useContext(ToastContext);

export { ToastProvider, useToast };
