import React from "react";
import "./CartDropdown.scss";
import EmptyState from "components/common/EmptyState";
import { emptyCart, greyLike, greyTrash } from "assets/images/svg";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useComponentVisible } from "helpers/customHooks";

interface Props {
  cartItems?: any;
}

const Notify: React.FC<Props> = ({ cartItems }) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(true);

  let ammountarray: any = [];
  for (let key in cartItems) {
    ammountarray.push(cartItems[key].amount);
  }

  let sum: number = ammountarray.reduce(function (a: any, b: number) {
    return a + b;
  }, 0);

  return (
    <>
      {isComponentVisible && (
        <div
          className="cart101_wrapper"
          onClick={() => setIsComponentVisible(!isComponentVisible)}
          ref={ref}
        >
          {cartItems.length === 0 ? (
            <EmptyState
              src={emptyCart}
              textContent={"Your cart is empty"}
              className={"cart101"}
              handleClick={() => {}}
            />
          ) : (
            <>
              <div className="overflow_wrapper">
                {cartItems.length > 0 &&
                  cartItems.map((item: any, index: number) => (
                    <>
                      <div className="cart101_content">
                        <img src={item?.course?.course?.cover} alt={"name"} />
                        <div className="cart101_text">
                          <span className="span_text">
                            {item?.course?.course?.title}
                          </span>
                          <p className="span_name">Daniel Ale</p>
                          <div className="amount_and_icon">
                            <p className="span_amount">{`NGN ${item?.amount}`}</p>
                            <p>
                              <img src={greyLike} alt="like" />
                              <img src={greyTrash} alt="like" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </>
                  ))}
              </div>
              <div className="cart101_footer">
                <div className="note_items">
                  <span className="footer_total">Total</span>
                  <span className="footer_amount">{`NGN ${sum}`}</span>
                </div>
                <Link to="/cart">
                  <Button>Go to cart </Button>
                </Link>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Notify;
