import React from 'react';
import './ProfileMenuDropdown.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'store/actions/authActions';
import { Link } from 'react-router-dom';
interface Props {
  toggleProfilemenu?: any;
  user?: any;
}

const Profile: React.FC<Props> = ({ toggleProfilemenu, user }) => {
  const { account_type } = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    window.location.href = '/';
  };
  return (
    <div className='profile-dropdown'>
      <div className='profile-dropdown__container d-flex flex-column mb-0'>
        <div className='d-flex profile-dropdown__item mb-0'>
          <p className='mr-2 mb-0'>Profile</p>
          <p className='mb-0 profile-dropdown__name'>
            {user?.firstname} {user?.lastname}
          </p>
        </div>
        {account_type === 'VENDOR' && (
          <div className='d-flex flex-column'>
            <Link
              to='/instructor/messages'
              className=' profile-dropdown__item profile-dropdown__item-hoverable'
            >
              Messages
            </Link>
            <Link
              to='/instructor/settings'
              className=' profile-dropdown__item profile-dropdown__item-hoverable'
            >
              Upgrade plan
            </Link>
            <Link
              to='/support-center'
              className=' profile-dropdown__item profile-dropdown__item-hoverable'
            >
              Support Center
            </Link>
            <Link
              to='/instructor/settings'
              className=' profile-dropdown__item profile-dropdown__item-hoverable'
            >
              Settings
            </Link>
            <Link
              to='#'
              onClick={() => handleLogout()}
              className='profile-dropdown__item profile-dropdown__item--red'
            >
              Log out
            </Link>
          </div>
        )}
        {account_type === 'LEARNER' && (
          <div className='d-flex flex-column'>
            <Link
              to='/courses'
              className=' profile-dropdown__item profile-dropdown__item-hoverable'
            >
              My courses
            </Link>
            <Link
              to='/wishlist'
              className=' profile-dropdown__item profile-dropdown__item-hoverable'
            >
              Wish list
            </Link>
            <Link
              to='/support-center'
              className=' profile-dropdown__item profile-dropdown__item-hoverable'
            >
              Messages
            </Link>

            <Link
              to='/settings'
              className=' profile-dropdown__item profile-dropdown__item-hoverable'
            >
              Settings
            </Link>
            <Link
              to='#'
              onClick={() => handleLogout()}
              className='profile-dropdown__item  profile-dropdown__item--red '
            >
              Log out
            </Link>

            <Link
              to='#'
              onClick={() => handleLogout()}
              className='profile-dropdown__item profile-dropdown__item--bold mb-2'
            >
              Become an Instructor
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
