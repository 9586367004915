import { WishlistActionTypes, GET_WISHLIST } from "store/types/wishlistTypes";
import { Dispatch } from "redux";
import { sendRequest } from "config/api";

export const getWishlist = () => async (
  dispatch: Dispatch<WishlistActionTypes>
) => {
  try {
    const { data }: any = await sendRequest("/lms/course/my_wishlist/");
    dispatch({
      type: GET_WISHLIST,
      payload: data,
    });
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};
