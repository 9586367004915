import * as React from 'react';
import './Radio.scss';
import { disabled } from 'glamor';
export interface RadioProps {
  htmlForId?: string;
  name: string;
  labelText?: string;
  value: string;
  handleChange: any;
  withBackground?: boolean;
  checked?: boolean;
  className?: string;
  dataIndex?: any;
  disabled?: any;
}

const Radio: React.SFC<RadioProps> = ({
  htmlForId,
  name,
  labelText,
  value,
  handleChange,
  withBackground = false,
  checked,
  dataIndex,
  className = '',
  disabled,
}) => {
  return (
    <label
      htmlFor={htmlForId}
      className={`inputradio ${
        withBackground && 'inputradio--background'
      } ${className}`}
    >
      <input
        type="radio"
        name={name}
        id={htmlForId}
        className="inputradio__input"
        value={value}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
        data-index={dataIndex}
      />
      <div className="inputradio__radio"></div>
      <span>{labelText}</span>
    </label>
  );
};

export default Radio;
