import {
  GET_COURSE_INFO,
  CourseStateI,
  GET_CATEGORIES,
  GET_VENDOR_COURSES,
  GET_MY_COURSES,
  GET_COURSE_AUTHENTICATED_USER,
  GET_RECOMMENDED_COURSES,
  GET_FREE_COURSES,
  GET_INTERESTED_COURSES,
  GET_POPULAR_COURSES,
  GET_FEATURED_COURSES,
  GET_VENDOR_ACTIVE_COURSES,
  GET_VENDOR_DRAFT_COURSES,
  CREATE_COURSE,
  GET_MY_ACTIVE_COURSES,
  GET_MY_DRAFT_COURSES,
} from 'store/types/courseTypes';
const initialState: CourseStateI = {
  course: {},
  createdCourse: {},
  categories: [],
  vendorCourses: [],
  vendorActiveCourses: [],
  vendorDraftCourses: [],
  myCourses: [],
  myActiveCourses: [],
  myDraftCourses: [],
  myCourse: [],
  recommendedcourses: [],
  freeCourse: [],
  featuredCourses: [],
  popularCourses: [],
  interestedCourses: [],
};

//would implement proper typings and interface later

const courseReducter = (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_COURSE_INFO:
      return {
        ...state,
        course: payload,
      };

    case GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case GET_VENDOR_COURSES:
      return {
        ...state,
        vendorCourses: payload,
      };
    case GET_MY_COURSES:
      return {
        ...state,
        myCourses: payload,
      };
    case GET_MY_ACTIVE_COURSES:
      return {
        ...state,
        myActiveCourses: payload,
      };
    case GET_MY_DRAFT_COURSES:
      return {
        ...state,
        myDraftCourses: payload,
      };
    case GET_COURSE_AUTHENTICATED_USER:
      return {
        ...state,
        myCourse: payload,
      };
    case GET_RECOMMENDED_COURSES:
      return {
        ...state,
        recommendedcourses: payload,
      };
    case GET_FREE_COURSES:
      return {
        ...state,
        freeCourse: payload,
      };
    case GET_POPULAR_COURSES:
      return {
        ...state,
        popularCourses: payload,
      };
    case GET_FEATURED_COURSES:
      return {
        ...state,
        featuredCourses: payload,
      };
    case GET_INTERESTED_COURSES:
      return {
        ...state,
        interestedCourses: payload,
      };

    case GET_VENDOR_ACTIVE_COURSES:
      return {
        ...state,
        vendorActiveCourses: payload,
      };
    case GET_VENDOR_DRAFT_COURSES:
      return {
        ...state,
        vendorDraftCourses: payload,
      };

    case CREATE_COURSE:
      return {
        ...state,
        createdCourse: payload,
      };
    default:
      return state;
  }
};

export default courseReducter;
