import { useLayoutEffect, useState, useEffect, useRef  } from 'react';


/**
 * This is a custom hook that listens for window screen size updates
 */
export function useWindowSize() {
  const [size, setSize] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}


export function capitalize(s: any) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}




export function useComponentVisible(initialIsVisible: any) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef<HTMLInputElement>(null);
  
  const handleClickOutside = (event: any) => {

    if (ref.current && ref.current.contains(event.target) && isComponentVisible) {
      setIsComponentVisible(true);
    }
   else if (ref.current && !ref.current.contains(event.target) && isComponentVisible) {
      setIsComponentVisible(false);
    }

  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

   return { ref, isComponentVisible, setIsComponentVisible };
}
