const Types = {
  GET_CART_SUCCESS: "GET_CART_SUCCESS",
  ERROR: "ERROR",
  REMOVE_ITEM: "REMOVE_ITEM",
  VERIFY_COUPON_CODE: "VERIFY_COUPON_CODE",
  ADD_WISHLIST: "ADD_WISHLIST",
  ADD_ITEM: "ADD_ITEM",
  CHECK_OUT: "CHECK_OUT",
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_WISHLIST: "REMOVE_WISHLIST",
  LOADING: 'LOADING',
  DELETE_COUPON: "DELETE_COUPON",
  CLEAR: 'CLEAR',
  ORDER_SUCCESS: 'ORDER_SUCCESS',
  ORDER_FAILURE: 'ORDER_FAILURE'
};

export default Types;
